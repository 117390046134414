import {FunctionComponent} from "react"

import CircleIcon from '@mui/icons-material/Circle';

import {InspectionStatus} from "@appTypes/inspection";

import styles from "./styles.module.css"
import {useTranslation} from "react-i18next";

interface InspectionProgressLabelProps {
    status: InspectionStatus
}

export const InspectionProgressLabel: FunctionComponent<InspectionProgressLabelProps> = ({status}) => {
    const {t} = useTranslation()

    return (
        <span className={`${styles[status]} ${styles.label}`}>
            <CircleIcon fontSize="inherit"/><span>{t(`common:InspectionStatus.${status}`)}</span>
        </span>
    )
}