import {api} from "@features/redux/api";
import {User} from "@appTypes/user";

type usersListQueryPaginatedResult = {
    count: number,
    list: User[]
}


type usersListQueryResult = User[]

type usersListQueryArgs = {
    search?: string,
    ordering?: "id" | "-id" | "email" | "-email" | "first_name" | "-first_name" | "last_name" | "-last_name",
    offset?: number,
    limit?: number,

    id?: string,
    id__in?: string,
    email?: string,
    email__in?: string,
    first_name?: string,
    first_name__in?: string,
    last_name?: string,
    last_name__in?: string,
}


type userDetailsQueryResult = User

type userDetailsQueryArgs = {
    id: number
}


type createUserQueryResult = User

type createUserQueryArgs = {
    password: string,
    password_confirmation: string,
    role: string,
} & Pick<User, 'email' | 'phone_number' | 'first_name' | 'last_name'>


type updateUserQueryResult = User

type updateUserQueryArgs = {
    id: number,
    password?: string,
    role?: string,
} & Partial<Pick<User, 'email' | 'phone_number' | 'first_name' | 'last_name'>>


type blockUserQueryResult = User

type blockUserQueryArgs = {
    id: number
}


type unBlockUserQueryResult = User

type unBlockUserQueryArgs = {
    id: number
}


type updateUserPasswordQueryResult = User

type updateUserPasswordQueryArgs = {
    id: number,
    new_password: string,
    new_password_confirmation: string,
}

type forgotPasswordQueryResult = {
    status: "ok";
}

type forgotPasswordQueryArgs = {
    email: User["email"];
}


type checkPasswordResetRequestResult = {
    status: "ok",
}

type checkPasswordResetRequestArgs = {
    id: string;
}

type resetPasswordQueryResult = {
    status: "ok";
}

type resetPasswordQueryArgs = {
    id: string;
    password: string;
}


export const usersApi = api.enhanceEndpoints({addTagTypes: ['User']}).injectEndpoints({
    endpoints: builder => ({
        usersList: builder.query<usersListQueryResult & usersListQueryPaginatedResult, usersListQueryArgs | void>({
            query: (params) => ({
                url: "admin/users",
                method: "GET",
                params: params,
            }),
            providesTags: (result) => {
                if (!result) {
                    return ['User']
                } else {
                    if (Object.keys(result).includes('list')) {
                        return [
                            ...(result as usersListQueryPaginatedResult).list.map(({id}, idx) => ({
                                type: 'User' as const,
                                id
                            }))
                        ]
                    } else {
                        return [
                            ...(result as usersListQueryResult)?.map(({id}, idx) => ({type: 'User' as const, id}))
                        ]
                    }
                }
            },
        }),
        userDetails: builder.query<userDetailsQueryResult, userDetailsQueryArgs>({
            query: ({id}) => ({
                url: `admin/users/${id}`,
                method: "GET",
            }),
            providesTags: (result) => {
                if (!result) {
                    return ['User']
                } else {
                    return [{type: 'User' as const, id: result.id}]
                }
            },
        }),
        createUser: builder.mutation<createUserQueryResult, createUserQueryArgs>({
            query: ({...args}) => ({
                url: `admin/users/create/`,
                method: "POST",
                body: args,
            }),
            invalidatesTags: (result) => {
                if (!result) {
                    return ['User']
                } else {
                    return [{type: 'User', id: result.id}]
                }
            },
        }),
        updateUser: builder.mutation<updateUserQueryResult, updateUserQueryArgs>({
            query: ({id, ...args}) => ({
                url: `admin/users/update/${id}/`,
                method: "PATCH",
                body: args,
            }),
            invalidatesTags: (result) => {
                if (!result) {
                    return ['User']
                } else {
                    return [{type: 'User', id: result.id}]
                }
            },
        }),
        blockUser: builder.mutation<blockUserQueryResult, blockUserQueryArgs>({
            query: ({id}) => ({
                url: `admin/users/block/${id}/`,
                method: "POST",
            }),
            invalidatesTags: (result) => {
                if (!result) {
                    return ['User']
                } else {
                    return [{type: 'User', id: result.id}]
                }
            },
        }),
        unBlockUser: builder.mutation<unBlockUserQueryResult, unBlockUserQueryArgs>({
            query: ({id}) => ({
                url: `admin/users/unblock/${id}/`,
                method: "POST",
            }),
            invalidatesTags: (result) => {
                if (!result) {
                    return ['User']
                } else {
                    return [{type: 'User', id: result.id}]
                }
            },
        }),
        updateUserPassword: builder.mutation<updateUserPasswordQueryResult, updateUserPasswordQueryArgs>({
            query: ({id, ...args}) => ({
                url: `admin/users/password/update/${id}/`,
                method: "PATCH",
                body: args,
            }),
            invalidatesTags: (result) => {
                if (!result) {
                    return ['User']
                } else {
                    return [{type: 'User', id: result.id}]
                }
            },
        }),
        forgotPassword: builder.mutation<forgotPasswordQueryResult, forgotPasswordQueryArgs>({
            query: ({ email }) => ({
                url: `user/reset-password/request`,
                method: "POST",
                body: {
                    email: email,
                },
            }),
        }),
        checkPasswordResetRequest: builder.query<checkPasswordResetRequestResult, checkPasswordResetRequestArgs>({
            query: ({ id }) => ({
                url: `user/reset-password/check/${id}`,
                method: "GET",
            }),
        }),
        resetPassword: builder.mutation<resetPasswordQueryResult, resetPasswordQueryArgs>({
            query: ({ id, password }) => ({
                url: `user/reset-password/${id}`,
                method: "POST",
                body: {
                    password: password,
                },
            }),
        }),
    })
})

export const {
    useUsersListQuery,
    useUserDetailsQuery,
    useUpdateUserMutation,
    useBlockUserMutation,
    useUnBlockUserMutation,
    useUpdateUserPasswordMutation,
    useCreateUserMutation,
    useForgotPasswordMutation,
    useCheckPasswordResetRequestQuery,
    useResetPasswordMutation,
} = usersApi
