import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {CompaniesCarousel} from "@components/common/carousels/CompaniesCarousel/CompaniesCarousel";
import {useTranslation} from "react-i18next";

export const SupportedCompaniesSection: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.section}>
            <h2 className={styles.title}>
                {t("user:Home.SupportedCompaniesSection.title")}
            </h2>
            <div className={styles.content}>
                <CompaniesCarousel/>
            </div>
        </div>
    )
}