import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {useTranslation} from "react-i18next";

interface InputErrorProps {
    error?: string | undefined,
    touched?: boolean,
    className?: string,
}

export const InputError: FunctionComponent<InputErrorProps> = ({error, touched = true, className = ""}) => {
    const {t} = useTranslation();

    if(touched && error) {
        return (
            <p className={`${styles.error} ${className}`}>
                {t(error)}
            </p>
        )
    }
    return null
}
