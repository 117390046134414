import {FunctionComponent, MouseEvent} from "react"

import styles from "./styles.module.css"
import {GreenButton} from "@components/common/buttons/GreenButton/GreenButton";
import {useCompaniesListQuery} from "@features/companies/api";
import {useInspectionPriceQuery} from "@features/inspection/api";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "@features/redux/store";
import {getMe} from "@features/auth/slice";
import {Image} from "@components/common/images/Image/Image";
import {useTranslation} from "react-i18next";

export const PricingSection: FunctionComponent = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const user = useAppSelector(getMe)

    const {data: companies} = useCompaniesListQuery({limit: 3})
    const {data: inspection_prices} = useInspectionPriceQuery()

    const getStarted = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.persist()

        const url = user ? "/dashboard" : "/sign_in"

        navigate(url)
    }

    if (!companies) {
        return null
    }

    return (
        <div className={styles.section}>
            <h2 className={styles.title}>{t("user:Home.PricingSection.heading")}</h2>
            <div className={styles.content}>
                {
                    (companies?.list || companies).map((company, idx) => (
                        <div className={styles.card} key={idx}>
                            <div className={styles.header}>
                                <div className={styles.image}>
                                    <Image src={company.logo} alt={company.name} skeletonWidth={174} skeletonHeight={174}/>
                                </div>
                                <p className={styles.productName}>{t("user:Home.PricingSection.companyInspectionLabel")}</p>
                            </div>
                            <div className={styles.footer}>
                                <h2 className={styles.price}>{inspection_prices?.single_inspection}$</h2>
                                <p>
                                    {t("user:Home.PricingSection.singleInspectionLabel")}
                                </p>
                            </div>
                        </div>
                    ))
                }
                {(companies?.list || companies).length > 1 &&
                    <>
                        <div className={styles.bundleCard}>
                            <div className={styles.header}>
                                <div className={styles.companies}>
                                    <div className={styles.image}>
                                        <Image src={(companies?.list || companies)[0].logo}
                                             alt={(companies?.list || companies)[0].name} skeletonWidth={87} skeletonHeight={87}/>
                                    </div>
                                    <h3 className={styles.plusSign}>+</h3>
                                    <div className={styles.image}>
                                        <Image src={(companies?.list || companies)[1].logo}
                                             alt={(companies?.list || companies)[1].name} skeletonWidth={87} skeletonHeight={87}/>
                                    </div>
                                </div>
                                <p className={styles.productName}>{t("user:Home.PricingSection.companyInspectionLabel")}</p>
                            </div>
                            <div className={styles.footer}>
                                <h2 className={styles.price}>
                                    {
                                        inspection_prices?.single_inspection && inspection_prices?.bundle_inspection &&
                                        inspection_prices?.single_inspection + inspection_prices?.bundle_inspection
                                    }$
                                </h2>
                                <p>
                                    {(companies?.list || companies)[0].name} + {(companies?.list || companies)[1].name} {t("user:Home.PricingSection.companyInspectionLabel")}
                                </p>
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className={styles.getStartedButtonContainer}>
                <GreenButton onClick={getStarted} className={styles.getStartedButton}>
                    {t("user:Home.PricingSection.getStartedButton")}
                </GreenButton>
            </div>
        </div>
    )
}