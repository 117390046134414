import Yup from "@helpers/yup-extended";

const massMailingFormValidationSchema = Yup.object().shape({
    registered_already_days: Yup.number().required().min(0),
    last_inspection_days: Yup.number().required().min(0),
});


export {
    massMailingFormValidationSchema,
}
