import {FunctionComponent, useRef, useState} from "react"

import EnLangIcon from "@assets/icons/EnLangIcon.svg"
import RuLangIcon from "@assets/icons/RuLangIcon.svg"
import ChLangIcon from "@assets/icons/ChLangIcon.svg"
import EsLangIcon from "@assets/icons/EsLangIcon.svg"

import {TransparentButton} from "@components/common/buttons/TransparentButton/TransparentButton"

import styles from "./styles.module.css"
import {Popover} from "@components/common/Popover/Popover"
import i18n from "@features/i18n"
import {Image} from "@components/common/images/Image/Image";

type LanguageDescription = {
    label: string,
    icon: string
}

interface Languages {
    [langKey: string]: LanguageDescription
}

const languages: Languages = {
    en: {
        label: 'En',
        icon: EnLangIcon,
    },
    es: {
        label: 'Es',
        icon: EsLangIcon,
    },
    ch: {
        label: 'Ch',
        icon: ChLangIcon,
    },
    ru: {
        label: 'Ru',
        icon: RuLangIcon,
    },
}

export const LanguageSwitcher: FunctionComponent = () => {
    const [isOpen, setOpen] = useState(false)

    const buttonRef = useRef(null)

    const switchLanguage = async (langCode: string) => {
        await i18n.changeLanguage(langCode)
        setOpen(false)
    }

    const togglePopover = () => {
        setOpen(prevState => !prevState)
    }
    const onClose = (event: MouseEvent | TouchEvent) => {
        event.preventDefault()
        setOpen(false)
    }

    return (
        <div>
            <TransparentButton onClick={togglePopover} ref={buttonRef} className={styles.button}>
                <Image src={languages[i18n.language].icon} alt={languages[i18n.language].label} className={styles.icon} skeletonWidth={24} skeletonHeight={24}/>
                <span className={styles.buttonLangLabel}>{languages[i18n.language].label}</span>
            </TransparentButton>
            <Popover open={isOpen} onClose={onClose} anchorElementRef={buttonRef} popperClassName={styles.popper} placement="bottom-end">
                {
                    Object.entries(languages).map(([langCode, languageDescription]) => {
                        if (!(i18n.options.supportedLngs || []).includes(langCode)) return
                        return (
                            <div key={langCode} onClick={() => switchLanguage(langCode)} className={styles.option}>
                                <Image src={languageDescription.icon} alt={languageDescription.label} className={styles.icon} skeletonWidth={24} skeletonHeight={24}/>
                                <span>{languageDescription.label}</span>
                            </div>
                        )
                    })
                }
            </Popover>
        </div>
    )
}