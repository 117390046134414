import {FunctionComponent, Suspense, useEffect} from "react"

import styles from "./styles.module.css"
import {InspectionFormStepper} from "@components/user/InspectionFormStepper/InspectionFormStepper";
import {useLocation} from "react-router-dom";
import {GeneralData} from "@components/user/InspectionSteps/GeneralData/GeneralData";
import {CarVerificationItems} from "@components/user/InspectionSteps/CarVerificationItems/CarVerificationItems";
import {CarInterior} from "@components/user/InspectionSteps/CarInterior/CarInterior";
import {CarExterior} from "@components/user/InspectionSteps/CarExterior/CarExterior";
import {CarTires} from "@components/user/InspectionSteps/CarTires/CarTires";
import {Checkout} from "@components/user/InspectionSteps/Checkout/Checkout";
import {Skeleton, Step, StepIcon, StepLabel} from "@mui/material";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import {InspectionFormHeader} from "@components/user/InspectionFormHeader/InspectionFormHeader";
import {InspectionFormDescription} from "@components/user/InspectionFormDescription/InspectionFormDescription";

interface Step {
    title?: string, // must be translation key. if undefined - no title will be displayed
    component: FunctionComponent<any>,
    description?: string, // must be translation key. if undefined - no description will be displayed
}

const STEPS: Step[] = [
    {
        component: GeneralData,
    },
    {
        title: "user:NewInspection.CarVerificationItems.title",
        component: CarVerificationItems,
        description: "user:NewInspection.CarVerificationItems.description",
    },
    {
        title: "user:NewInspection.CarInterior.title",
        component: CarInterior,
        description: "user:NewInspection.CarInterior.description",
    },
    {
        title: "user:NewInspection.CarExterior.title",
        component: CarExterior,
        description: "user:NewInspection.CarExterior.description",
    },
    {
        title: "user:NewInspection.CarTires.title",
        component: CarTires,
        description: "user:NewInspection.CarTires.description",
    },
    {
        title: "user:NewInspection.Checkout.title",
        component: Checkout,
        description: "user:NewInspection.Checkout.description",
    }
]

export const NewInspection: FunctionComponent = () => {
    const location = useLocation()
    
    const state = location.state || {activeStep: 0}
    
    const activeStep = STEPS[state.activeStep]

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [activeStep])
    
    return (
        <div className={styles.page}>
            <InspectionFormStepper activeStep={state.activeStep}>
                {STEPS.map(({title}, idx) => (
                    <Step key={idx}>
                        <StepLabel StepIconComponent={(props) => (
                            <StepIcon {...props}
                                      icon={props.completed ? <CheckCircleRoundedIcon/> : <CloseRoundedIcon/>}/>
                        )}
                        />
                    </Step>
                ))}
            </InspectionFormStepper>
            <Suspense fallback={<Skeleton/>}>
                {activeStep?.title && <InspectionFormHeader stepNumber={location.state.activeStep} title={activeStep.title}/>}
            </Suspense>
            <Suspense fallback={<Skeleton/>}>
                {activeStep?.description && <InspectionFormDescription description={activeStep.description}/>}
            </Suspense>
            <Suspense fallback="loading">
                {activeStep && <activeStep.component title={activeStep.title} description={activeStep.description}/>}
            </Suspense>
        </div>
    )
}