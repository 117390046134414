import {FunctionComponent} from "react"

import {useField} from "formik";
import {FieldHookConfig} from "formik/dist/Field";
import {GenericFieldHTMLAttributes} from "formik/dist/types";
import { InputError } from "@components/common/InputError/InputError";
import styles from "./styles.module.css"

interface WhiteTextAreaProps  {
    label: string,
    className?: string,
    resizable?: boolean,
}

export const WhiteTextArea: FunctionComponent<WhiteTextAreaProps & GenericFieldHTMLAttributes & FieldHookConfig<string>> = ({label, className = "", resizable = false, ...fieldProps}) => {
    const [field, meta, helpers] = useField(fieldProps)

    return (
        <div className={styles.container}>
            <label htmlFor={field.name} className={styles.label}>{label}</label>
            <>
                <InputError error={meta.error} touched={meta.touched}/>
                <textarea className={`${styles.input} ${!resizable ? styles.nonResizable: ""}`} placeholder={fieldProps.placeholder} disabled={fieldProps.disabled} {...field}/>
            </>
        </div>
    );
}
