import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {
    BaseAccordion,
} from "@components/common/accordions/BaseAccordion/BaseAccordion";

const PrimaryAccordion = BaseAccordion

PrimaryAccordion.defaultProps = {
    className: styles.accordion
}

PrimaryAccordion.Header.defaultProps = {
    className: styles.header,
    openIcon: <p className={styles.openIcon}>+</p>,
    closeIcon: <p className={styles.closeIcon}>-</p>,
}

PrimaryAccordion.Body.defaultProps = {
    className: styles.body,
    wrapperClassName: styles.bodyWrapper
}

export {
    PrimaryAccordion
}
