import {FunctionComponent, Suspense, useEffect, useLayoutEffect, useState} from "react"

import styles from "./styles.module.css"
import {Navigate, useLocation} from "react-router-dom"

import {FinishSignUp as Form} from "@components/user/forms/FinishSignUp/FinishSignUp"
import SignInBackground from "@assets/media/backgrounds/SignInBackground.png";
import {useTranslation} from "react-i18next";

export const FinishSignUp: FunctionComponent = () => {
    const {t} = useTranslation()
    const location = useLocation()

    useEffect(() => {
        return () => {
            window.history.replaceState({}, "")
        }
    }, [location])

    if (!location.state) {
        return (
            <Navigate to="/" replace/>
        )
    }

    return (
        <div className={styles.page}>
            <div className={styles.background}>
                <img src={SignInBackground} alt="SignInBackground"/>
            </div>
            <div className={styles.content}>
                <div>
                    <h1 className={styles.title}>
                        {t("user:FinishSignUp.heading")}
                    </h1>
                    <p className={styles.description}>
                        {t("user:FinishSignUp.description")}
                    </p>
                    <Suspense fallback="Loading...">
                        <Form/>
                    </Suspense>
                </div>
            </div>
        </div>
    )
}