import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {Form, Formik, FormikHelpers} from "formik";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getFormData, getFormValues, setFormData} from "@helpers/multiStepForm";
import {NEW_INSPECTION_FORM_DATA_KEY} from "@helpers/constants/inspection";
import {carVerificationItemsValidationSchema} from "@helpers/validationSchemas/new-inspection";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {WhiteCameraImageInput} from "@components/common/inputs/WhiteCameraImageInput/WhiteCameraImageInput";
import {InspectionPhoto} from "@appTypes/files";
import {HintInterceptor} from "@helpers/components/HintInterceptor/HintInterceptor";

import RegistrationCardHint from "@assets/media/hints/RegistrationCardHint.jpg"
import OdometerHint from "@assets/media/hints/OdometerHint.jpg"

interface CarVerificationItemsProps {
    title?: string,
    description?: string,
}

interface Values {
    registration_card: InspectionPhoto | null,
    odometer: InspectionPhoto | null,
}

export const CarVerificationItems: FunctionComponent<CarVerificationItemsProps> = () => {
    const initialValues: Values = {
        registration_card: null,
        odometer: null,
        ...getFormValues(NEW_INSPECTION_FORM_DATA_KEY, 'registration_card', 'odometer'),
    }

    const navigate = useNavigate()
    const location = useLocation()

    const {t} = useTranslation()

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        setFormData(NEW_INSPECTION_FORM_DATA_KEY, {
            ...getFormData(NEW_INSPECTION_FORM_DATA_KEY),
            ...values
        })

        navigate(location, {
            ...location,
            state: {
                activeStep: 2,
            },
        })
        setSubmitting(false)
    }

    return (
        <div className={styles.step}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}
                    validationSchema={carVerificationItemsValidationSchema}>
                {({
                      isSubmitting,
                      errors,
                  }) => (
                    <Form className={styles.form}>
                        <HintInterceptor hintKey="registration_card"
                                         hintTitle={t("user:NewInspection.CarVerificationItems.hints.RegistrationCard.title")}
                                         hintDescription={t("user:NewInspection.CarVerificationItems.hints.RegistrationCard.description")}
                                         hintFileSource={RegistrationCardHint}
                                         hintFileType="image">
                            <WhiteCameraImageInput
                                label={t("user:NewInspection.CarVerificationItems.RegistrationCardImageInput.label")}
                                name="registration_card"/>
                        </HintInterceptor>
                        <HintInterceptor hintKey="odometer"
                                         hintTitle={t("user:NewInspection.CarVerificationItems.hints.Odometer.title")}
                                         hintDescription={t("user:NewInspection.CarVerificationItems.hints.Odometer.description")}
                                         hintFileSource={OdometerHint}
                                         hintFileType="image">
                            <WhiteCameraImageInput
                                label={t("user:NewInspection.CarVerificationItems.OdometerImageInput.label")}
                                name="odometer"/>
                        </HintInterceptor>
                        <div className={styles.submitButton}>
                            <GreenRoundedButton type="submit" disabled={isSubmitting || !!Object.values(errors).length}>
                                {t("common:Inspection.NextStepButton")}
                            </GreenRoundedButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}