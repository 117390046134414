import {FormResponseErrorType, ResponseErrorType} from "@appTypes/api"

type WrappedApiErrorResponse = {data: ResponseErrorType, code: number}
type WrappedApiFormErrorResponse = {data: FormResponseErrorType, code: number}

export function isApiResponse(error: unknown): error is WrappedApiErrorResponse {
    return (
        typeof error === "object" &&
        error != null &&
        Object.keys(error).includes("data") &&
        typeof (error as WrappedApiErrorResponse).data === "object"
    )
}

export function isApiFormResponse(error: unknown): error is WrappedApiFormErrorResponse {
    return (
        typeof error === "object" &&
        error != null &&
        Object.keys(error).includes("data") &&
        Object.keys((error as WrappedApiFormErrorResponse).data).includes("errors")
    )
}

export function convertApiError(error: {data: ResponseErrorType, code: number}): ResponseErrorType {
    return {
        message: error.data.message,
        code: error.data.code
    }
}

export function convertApiFormErrors(error: {data: FormResponseErrorType, code: number}): FormResponseErrorType {
    return {
        errors: error.data.errors,
        code: error.data.code
    }
}
