import {FunctionComponent} from "react"

import {Card as InspectionCard} from "@components/user/InspectionsList/Card/Card"

import {Inspection} from "@appTypes/inspection";

import styles from "./styles.module.css"

interface InspectionsListProps {
    inspections: Inspection[]
}

export const InspectionsList: FunctionComponent<InspectionsListProps> = ({inspections}) => {
    return (
        <div className={styles.list}>
            {
                inspections.map((inspection, idx) => (
                    <InspectionCard inspection={inspection} key={idx}/>
                ))
            }
        </div>
    )
}