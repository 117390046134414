import {ChangeEvent, FunctionComponent, SetStateAction, useEffect, useState} from "react"

import styles from "./styles.module.css"
import {useDebouncedFunction} from "@helpers/debounce";

interface ListPaginationProps {
    count: number,
    limit: number,
    setLimit: (limit: number) => void | SetStateAction<number>,
    currentPage: number,
    setCurrentPage: (pageNumber: number) => void | SetStateAction<number>
}

export const ListPagination: FunctionComponent<ListPaginationProps> = ({count, limit, setLimit, currentPage, setCurrentPage}) => {
    const [displayLimit, setDisplayLimit] = useState<number>(limit)

    const debouncedCheck = useDebouncedFunction((newValue: number) => {
        let newLimit = newValue || 1;

        if (newValue > 20) {
            newLimit = 20
        }
        else if (newValue < 1) {
            newLimit = 1
        }

        setDisplayLimit(newLimit)
        setLimit(newLimit)
    }, 300, true)

    const onLimitChange = async (event: ChangeEvent<HTMLInputElement>) => {
        let newLimit = parseInt(event.target.value)

        debouncedCheck(newLimit)

        setDisplayLimit(newLimit)
    }

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1)
    }

    const goToPreviousPage = () => {
        setCurrentPage(currentPage - 1)
    }

    const goToFirstPage = () => {
        setCurrentPage(0)
    }

    const goToLastPage = () => {
        setCurrentPage(Math.floor((count - 1) / limit));
    }

    return (
        <div className={styles.pagination}>
            <div className={styles.rowsNumber}>
                <label>
                    Rows per page:
                </label>
                <input type="number" value={displayLimit} onChange={onLimitChange} min={1} max={20}/>
            </div>
            <div className={styles.controls}>
                <button disabled={currentPage <= 0} onClick={goToFirstPage}>
                    First
                </button>
                <button disabled={currentPage <= 0} onClick={goToPreviousPage}>
                    Previous
                </button>
                <button disabled={currentPage >= Math.floor((count - 1) / limit)} onClick={goToNextPage}>
                    Next
                </button>
                <button disabled={currentPage >= Math.floor((count - 1) / limit)} onClick={goToLastPage}>
                    Last
                </button>
            </div>
        </div>
    )
}