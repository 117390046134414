import {Children, cloneElement, FunctionComponent, isValidElement, MouseEvent, ReactElement, useState} from "react"

import styles from "./styles.module.css"
import {Header, HeaderProps} from "@components/common/accordions/BaseAccordion/Header/Header";
import {Body, BodyProps} from "@components/common/accordions/BaseAccordion/Body/Body";

export interface BaseAccordionProps {
    className?: string,
    children: ReactElement[]
}

export interface BaseAccordionNestedElements {
    Header: FunctionComponent<HeaderProps>,
    Body: FunctionComponent<BodyProps>
}

const BaseAccordion: FunctionComponent<BaseAccordionProps> & BaseAccordionNestedElements = ({className = "", children}) => {
    const [open, setOpen] = useState<boolean>(false)

    const childrenArray = Children.toArray(children)

    const header = childrenArray.find((child) => isValidElement(child) && child.type === Header)
    const body = childrenArray.find((child) => isValidElement(child) && child.type === Body)

    const onOpen = (event: MouseEvent<HTMLElement>) => {
        setOpen(true)
    }

    const onClose = (event: MouseEvent<HTMLElement>) => {
        setOpen(false)
    }

    return (
        <div className={`${styles.accordion} ${className}`}>
            {isValidElement(header) && cloneElement((header as ReactElement), {open: open, onOpen: onOpen, onClose: onClose})}
            {isValidElement(body) && cloneElement((body as ReactElement), {open: open})}
        </div>
    )
}

BaseAccordion.Header = Header
BaseAccordion.Body = Body

export {
    BaseAccordion
}
