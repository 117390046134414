import {FunctionComponent, ReactElement} from "react"

import styles from "./styles.module.css"

interface WhiteInfoCardProps {
    label: string,
    info: string | ReactElement | ReactElement[],
    className?: string,
}

export const WhiteInfoCard: FunctionComponent<WhiteInfoCardProps> = ({label, info, className = ""}) => {
    return (
        <div className={`${styles.card} ${className}`}>
            <h4 className={styles.label}>{label}</h4>
            <h5 className={styles.info}>{info}</h5>
        </div>
    )
}