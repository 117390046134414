import {FunctionComponent, useEffect} from "react"

import styles from "./styles.module.css"
import {OverlayingPopup, OverlayingPopupProps} from "@components/common/popups/OverlayingPopup/OverlayingPopup";
import {useLocation, useNavigate} from "react-router-dom";
import {STEPS} from "@components/admin/Connect2FAPopup/steps";

interface Connect2FAPopupProps extends Omit<OverlayingPopupProps, "children"> {}

export const Connect2FAPopup: FunctionComponent<Connect2FAPopupProps> = ({isOpened, onClose}) => {
    const location = useLocation()
    const navigate = useNavigate()

    const state = location.state || {activeStep: 0}

    const activeStep = STEPS[state.activeStep]

    useEffect(() => {
        navigate(location, {
            state: {activeStep: 0}
        })
    }, [isOpened])

    return (
        <OverlayingPopup onClose={onClose} isOpened={isOpened}>
            <div className={styles.contents}>
                {activeStep && <activeStep.component title={activeStep.title} description={activeStep.description}/>}
            </div>
        </OverlayingPopup>
    )
}
