import Yup, {inspectionCertificateSchema} from "@helpers/yup-extended";

export const acceptInspectionRequestFormValidationSchema = Yup.object().shape({

})

export const rejectInspectionRequestFormValidationSchema = Yup.object().shape({
    incorrect_fields: Yup.array().of(Yup.string()),
    rejection_reason: Yup.string(),
})
