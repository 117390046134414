import {ChangeEvent, ChangeEventHandler, FunctionComponent, ReactElement, useId} from "react"

import styles from "./styles.module.css"
import {GenericFieldHTMLAttributes} from "formik/dist/types";
import {FieldHookConfig} from "formik/dist/Field";
import {useField} from "formik";
import {InputError} from "@components/common/InputError/InputError";

interface WhiteCheckboxProps {
    label: string | ReactElement,
    onChange?: ChangeEventHandler,
    className?: string,
}

export const WhiteCheckbox: FunctionComponent<WhiteCheckboxProps & FieldHookConfig<boolean>> = ({label, onChange,  className = "", ...fieldProps}) => {
    const [field, meta, helpers] = useField(fieldProps)

    const id = useId()

    const innerOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) onChange(event)

        field.onChange(event)
    }

    return (
        <div className={styles.container}>
            <input {...field} id={id} value={undefined} type="checkbox" className={`${styles.input} ${meta.touched && meta.error ? styles.error : ""}`} onChange={innerOnChange} checked={field.value}/>
            <label htmlFor={id} className={styles.label}>{label}</label>
        </div>
    )
}
