import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {Connect2FA} from "@components/admin/Connect2FA/Connect2FA";
import {MassMailing} from "@components/admin/MassMailing";

export const Settings: FunctionComponent = () => {
    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <h2>Available Integrations</h2>
                <p>Amet malesuada facilisis pretium tincidunt iaculis et.</p>
                <Connect2FA/>
                <div className={styles.massMailingContainer}>
                    <MassMailing/>
                </div>
            </div>
        </div>
    )
}