import {ButtonHTMLAttributes, FunctionComponent, MouseEvent} from "react"

import styles from "./styles.module.css"
import GoogleIcon from "@assets/icons/social/GoogleIcon.svg";
import {TokenResponse, useGoogleLogin} from "@react-oauth/google";
import {FormikHelpers, useFormikContext} from "formik";

interface GoogleAuthButton {
    text?: "continue_with",
    className?: string,
    onSuccess: (response: TokenResponse, setErrors?: FormikHelpers<unknown>['setErrors']) => void,
}

export const GoogleAuthButton: FunctionComponent<GoogleAuthButton & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick">> = ({text = "continue_with", className = "", onSuccess, ...buttonProps}) => {
    const formik = useFormikContext()

    const googleLogin = useGoogleLogin({
        onSuccess: (response) => onSuccess(response, formik.setErrors),
        scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    })

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        googleLogin()
    }

    return (
        <button className={`${styles.button} ${className}`} {...buttonProps} onClick={handleClick}>
            <img src={GoogleIcon} alt="GoogleIcon"/> <span>Continue with google</span>
        </button>
    )
}