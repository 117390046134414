import {FunctionComponent, Suspense, useEffect} from "react"

import {ContactUs as Form} from "@components/user/forms/ContactUs/ContactUs"

import styles from "./styles.module.css"
import {ContactsCard} from "@components/user/ContactsCard/ContactsCard";
import {useTranslation} from "react-i18next";

export const ContactUs: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <div className={styles.formWrapper}>
                    <div className={styles.header}>
                        <h2 className={styles.title}>{t("user:ContactUs.heading")}</h2>
                        <p className={styles.description}>
                            {t("user:ContactUs.description")}
                        </p>
                    </div>
                    <div className={styles.form}>
                        <Suspense fallback="Loading">
                            <Form/>
                        </Suspense>
                    </div>
                </div>
                <div className={styles.info}>
                    <ContactsCard/>
                </div>
            </div>
        </div>
    )
}