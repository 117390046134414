import {FunctionComponent, ReactElement} from "react"

import styles from "./styles.module.css"

interface SuccessProps{
    title?: ReactElement | string,
    description?: ReactElement | string,
}

export const Success: FunctionComponent<SuccessProps> = ({title, description}) => {
    return (
        <div className={styles.step}>
            {title && <h2>{title}</h2>}
            {description && <p>{description}</p>}
        </div>
    )
}