import {KeyboardEvent, FunctionComponent, useRef, useState, ChangeEvent, useEffect, HTMLInputTypeAttribute} from "react"

import styles from "./styles.module.css"
import {useField} from "formik";
import {uuid4} from "@helpers/uuid";
import {InputError} from "@components/common/InputError/InputError";

interface BaseMultiInputClassNames {
    groupClassName?: string,
    inputClassName?: string,
    errorsContainer?: string,
    wrapperClassName?: string,
}

interface BaseMultiInput {
    name: string,
    length: number,
    classNames?: BaseMultiInputClassNames,
    type?: HTMLInputTypeAttribute,
}

type InputValue = {
    [key: string]: string;
}

export const BaseMultiInput: FunctionComponent<BaseMultiInput> = ({name, length, classNames, type = "text"}) => {
    const [field, meta, helpers] = useField(name)

    const [inputs, setInputs] = useState<InputValue>(Array(length).fill(null).reduce((obj, item) => Object.assign(obj, {[uuid4()]: ""}), {}))
    const inputRefs = useRef<(HTMLElement | null)[]>([])

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        event.persist()

        if (!inputRefs.current) return;

        const currentInputRefIndex = inputRefs.current.indexOf(event.target as HTMLElement)

        if (event.key === "Backspace") {
            if (currentInputRefIndex >= 0) {
                const name = (event.target as HTMLInputElement).name

                setInputs((prevState) => ({...prevState, [name]: ""}))

                if (currentInputRefIndex > 0) {
                    inputRefs.current[currentInputRefIndex - 1]?.focus()
                }

                event.preventDefault()

                return;
            }
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.persist()

        const {name, value} = event.target;

        setInputs((prevInputs) => ({...prevInputs, [name]: value.slice(-1)}));

        const currentIndex = inputRefs.current.indexOf(event.target)

        if (currentIndex >= inputRefs.current.length - 1) {

        } else {
            inputRefs.current[currentIndex + 1]?.focus()
        }
    }

    useEffect(() => {
        const totalValue = Object.values(inputs).join("")

        helpers.setValue(totalValue)
    }, [inputs]);

    return (
        <div className={`${styles.wrapper} ${classNames?.wrapperClassName || ""}`}>
            <div className={`${styles.errorsContainer} ${classNames?.errorsContainer || ""}`}>
                <InputError error={meta.error} touched={meta.touched}/>
            </div>
            <div className={`${styles.group} ${classNames?.groupClassName || ""}`}>
                {
                    Object.keys(inputs).map((name, index) => (
                        <input key={index} name={name} maxLength={1}
                               ref={el => el && !inputRefs.current.includes(el) && (inputRefs.current.push(el))}
                               value={inputs.hasOwnProperty(name) ? inputs[name] : ""} onKeyDown={handleKeyDown}
                               onChange={handleChange} className={`${styles.input} ${classNames?.inputClassName || ""}`} type={type}/>
                    ))
                }
            </div>
        </div>
    )
}