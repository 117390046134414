import {FunctionComponent} from "react"

import WhatsAppIcon from "@assets/icons/social/WhatsAppIcon.svg";
import TelegramIcon from "@assets/icons/social/TelegramIcon.svg";
import InstagramIcon from "@assets/icons/social/InstagramIcon.svg";
import FacebookIcon from "@assets/icons/social/FacebookIcon.svg";

import styles from "./styles.module.css"
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const Footer: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.contents}>
            <div className={styles.info}>
                <div className={styles.generalInfo}>
                    <h3>{t("common:company.name")}</h3>
                    <p>{t("common:company.description")}</p>
                </div>
                <div className={styles.contacts}>
                    <h3>{t("common:contacts.title")}</h3>
                    <Link to={`mailto:${t("common:contacts.supportEmail")}`}>{t("common:contacts.supportEmail")}</Link>
                    <Link to={`tel:${t("common:contacts.supportPhoneNumber")}`}>{t("common:contacts.supportPhoneNumber")}</Link>
                    <p>{t("common:contacts.location")}</p>
                </div>
                <div className={styles.socials}>
                    <h3>{t("user:Footer.socialsTitle")}</h3>
                    <div className={styles.icons}>
                        {/*<a href={t("common:socials.WhatsApp")?.toString()}>*/}
                        {/*    <img src={WhatsAppIcon} alt={"WhatsAppIcon"}/>*/}
                        {/*</a>*/}
                        <a href={t("common:socials.Telegram")?.toString()}>
                            <img src={TelegramIcon} alt={"TelegramIcon"}/>
                        </a>
                        <a href={t("common:socials.Instagram")?.toString()}>
                            <img src={InstagramIcon} alt={"InstagramIcon"}/>
                        </a>
                        <a href={t("common:socials.Facebook")?.toString()}>
                            <img src={FacebookIcon} alt={"FacebookIcon"}/>
                        </a>
                    </div>
                </div>
                <div className={styles.blog}>
                    {/*    <h3>Blog</h3>*/}
                    {/*    <p>Dictum tincidunt</p>*/}
                {/*    <p>Natoque ut</p>*/}
                {/*    <p>Id posuere</p>*/}
                </div>
            </div>
            <div className={styles.copyrights}>
                <p>{t("common:company.name")} &copy; {new Date().getFullYear()}</p>
            </div>
        </div>
    );
}
