import {ChangeEvent, ChangeEventHandler, FunctionComponent, ReactNode} from "react";

import {useField} from "formik";
import {FieldHookConfig} from "formik/dist/Field";
import {GenericFieldHTMLAttributes} from "formik/dist/types";
import { InputError } from "@components/common/InputError/InputError";

import styles from "./styles.module.css"

interface WhiteSelectInputProps {
    onChange?: (event: ChangeEvent<HTMLSelectElement>) => void,
    label: string,
    multiple?: boolean,
    noValueOption?: boolean,
    children?: ReactNode | ReactNode[],
}

export const WhiteSelectInput: FunctionComponent<WhiteSelectInputProps & GenericFieldHTMLAttributes & FieldHookConfig<string>> = ({label, multiple = false, onChange, noValueOption = false, children, ...fieldProps}) => {
    const [field, meta, helpers] = useField(fieldProps)
    const handleChange = (event: ChangeEvent<HTMLSelectElement>, formikOnChange: ChangeEventHandler<HTMLElement> | undefined) => {
        if (onChange) {
            onChange(event)
        }
        if (formikOnChange) {
            formikOnChange(event)
        }
    }

    return (
        <div className={styles.container}>
            <label htmlFor={field.name} className={styles.label}>{label}</label>
            <>
                <InputError error={meta.error} touched={meta.touched}/>
                <select className={styles.input} multiple={multiple} placeholder={fieldProps.placeholder} {...field} onChange={(event) => {handleChange(event, field.onChange)}} disabled={fieldProps.disabled}>
                    {noValueOption && <option>---</option>}
                    {children}
                </select>
            </>
        </div>
    );
}
