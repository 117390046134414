import {FunctionComponent, MouseEvent, useState} from "react"

import styles from "./styles.module.css"
import {Form, Formik, FormikErrors, FormikHelpers} from "formik";
import {finishSignUpFormValidationSchema} from "@helpers/validationSchemas/auth";
import {NonFieldErrors} from "@components/common/NonFieldErrors/NonFieldErrors";
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {WhiteCheckbox} from "@components/common/checkboxes/WhiteCheckbox/WhiteCheckbox";
import {Link, useLocation} from "react-router-dom";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";
import {useTranslatedSchema} from "@helpers/hooks/useTranslatedSchema";
import {useFinishSignUpWithGoogleMutation} from "@features/oauth/google/api";
import {TermsOfUsePopup} from "@components/user/popups/TermsOfUsePopup/TermsOfUsePopup";
import {useTranslation} from "react-i18next";

interface Values {
    first_name: string,
    last_name: string,
    email: string,
    phone_number: string,
    user_agreement: boolean,
}

export const FinishSignUp: FunctionComponent = () => {
    const {t} = useTranslation()
    const location = useLocation()

    const initialValues: Values = {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "+1",
        user_agreement: false,
        ...location.state,
    }

    const [finishSignUpWithGoogle, {isLoading}] = useFinishSignUpWithGoogleMutation()

    const [isUserAgreementModalOpen, setUserAgreementModalOpen] = useState<boolean>(false)

    const schema = useTranslatedSchema(finishSignUpFormValidationSchema)

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        try {
            await finishSignUpWithGoogle({...values, email: location.state.email}).unwrap()

            setSubmitting(false)
        } catch (e) {
            setSubmitting(false)
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)
                setErrors(errors.errors as FormikErrors<Values>)
            }
        }
    }

    return (
        <>
            <Formik initialValues={initialValues} validationSchema={schema}
                    onSubmit={onSubmit} className={styles.form}>
                {({
                      isSubmitting,
                      errors,
                  }) => (
                    <Form className={styles.form}>
                        <NonFieldErrors/>
                        <WhiteInput name="first_name" label={t("user:FinishSignUp.form.FirstName.Label")}
                                    placeholder={t("user:FinishSignUp.form.FirstName.Placeholder") as string}/>
                        <WhiteInput name="last_name" label={t("user:FinishSignUp.form.LastName.Label")}
                                    placeholder={t("user:FinishSignUp.form.LastName.Placeholder") as string}/>
                        <WhiteInput name="email" label={t("user:FinishSignUp.form.Email.Label")} type="email"
                                    placeholder={t("user:FinishSignUp.form.Email.Placeholder") as string} disabled/>
                        <WhiteInput name="phone_number" label={t("user:FinishSignUp.form.PhoneNumber.Label")} type="tel"
                                    placeholder={t("user:FinishSignUp.form.PhoneNumber.Placeholder") as string}/>
                        <WhiteCheckbox label={<span>{t("user:FinishSignUp.form.UserAgreement.Label")}<Link to="#" className={styles.link} onClick={(event: MouseEvent<HTMLAnchorElement>) => {setUserAgreementModalOpen(true); event.preventDefault()}}>{t("user:FinishSignUp.form.UserAgreement.LinkLabel")}</Link></span>} name="user_agreement"/>
                        <div className={styles.submit}>
                            <GreenRoundedButton type="submit" disabled={isSubmitting || !!Object.values(errors).length || isLoading}>
                                {t("user:FinishSignUp.form.SubmitButton")}
                            </GreenRoundedButton>
                        </div>
                    </Form>
                )}
            </Formik>
            <TermsOfUsePopup open={isUserAgreementModalOpen} onClose={() => setUserAgreementModalOpen(false)}/>
        </>
    )
}