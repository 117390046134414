import {FunctionComponent, Suspense} from "react"

import styles from "./styles.module.css"
import SignInBackground from "@assets/media/backgrounds/SignInBackground.png";
import {ResetPassword as Form} from "@components/user/forms/ResetPassword/ResetPassword"
import {useTranslation} from "react-i18next";

export const ResetPassword: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.page}>
            <div className={styles.background}>
                <img src={SignInBackground} alt="SignInBackground"/>
            </div>
            <div className={styles.content}>
                <div>
                    <h1 className={styles.title}>
                        {t("user:ResetPassword.title")}
                    </h1>
                    <Suspense fallback="Loading...">
                        <Form/>
                    </Suspense>
                </div>
            </div>
        </div>
    )
}