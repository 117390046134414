import {FunctionComponent, useState} from "react"

import { useAdminActiveInspectionDetailsQuery, useAdminStartInspectionMutation} from "@features/inspection/api";
import {InspectionProgressLabel} from "@components/common/InspectionProgressLabel/InspectionProgressLabel";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {AcceptInspectionPopup} from "@components/admin/popups/AcceptInspectionPopup/AcceptInspectionPopup";
import {WhiteInfoCard} from "@components/common/WhiteInfoCard/WhiteInfoCard";
import {RedRoundedButton} from "@components/common/buttons/RedRoundedButton/RedRoundedButton";
import {RejectInspectionPopup} from "@components/admin/popups/RejectInspectionPopup/RejectInspectionPopup";
import {InspectionHandlerLabel} from "@components/admin/InspectionHandlerLabel/InspectionHandlerLabel";
import {TabPanel, Tabs, TabsList} from "@mui/base";
import {GreenTab} from "@components/admin/tabs/GreenTab/GreenTab";
import {LightBox} from "@helpers/components/LightBox/LightBox";
import {useParams} from "react-router-dom";

import styles from "./styles.module.css";
import {getAmericanFormattedDateTimeString} from "@helpers/date";
import {ImageLightbox} from "@helpers/components/ImageLightbox";

export const ActiveInspectionDetails: FunctionComponent = () => {
    const {id} = useParams();

    const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false);
    const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);

    const {data: inspection} = useAdminActiveInspectionDetailsQuery(parseInt(id as string), {
        refetchOnMountOrArgChange: true
    });

    const [startInspection] = useAdminStartInspectionMutation();

    if (!inspection) {
        return null;
    }

    return (
        <>
            <div className={styles.page}>
                <div className={styles.content}>
                    <div className={styles.info}>
                        <WhiteInfoCard label="License Plate Number" info={inspection.license_plate_number}/>
                        {
                            inspection.applicant_drivers_license &&
                            <WhiteInfoCard label="Applicant driver's license" info={inspection.applicant_drivers_license}/>
                        }
                        <WhiteInfoCard label="Location" info={`${inspection.inspection_region.name}(${inspection.inspection_country.name})${inspection.inspection_city ? " " + inspection.inspection_city.name : ""}`}/>
                        <div className={styles.companies}>
                            {
                                inspection.inspection_companies.map((company, idx) => (
                                    <img src={company.logo} alt={company.name} key={idx}/>
                                ))
                            }
                        </div>
                        {
                            inspection.user &&
                            <WhiteInfoCard label="User"
                                           info={`${inspection.user.first_name} ${inspection.user.last_name}`}/>
                        }
                        <WhiteInfoCard label="Status" info={<InspectionProgressLabel status={inspection.status}/>}/>
                        <WhiteInfoCard label="Date created" info={getAmericanFormattedDateTimeString(inspection.date_created)}/>
                    </div>
                    <div className={styles.filesSection}>
                        <div className={styles.title}>
                            <div className={styles.processedByLabel}>
                                {
                                    inspection?.processed_by &&
                                    <InspectionHandlerLabel
                                        processed_by={`${inspection?.processed_by?.first_name} ${inspection?.processed_by?.last_name}`}/>
                                }
                                <WhiteInfoCard label="Date modified" info={getAmericanFormattedDateTimeString(inspection.date_modified)}/>
                            </div>
                            <h2>Files</h2>
                        </div>
                        <div className={styles.files}>
                            <Tabs defaultValue={0}>
                                <TabsList className={styles.tabsList}>
                                    <GreenTab value={0}>Car verification items</GreenTab>
                                    <GreenTab value={1}>Car interior</GreenTab>
                                    <GreenTab value={2}>Car exterior</GreenTab>
                                    <GreenTab value={3}>Tires</GreenTab>
                                </TabsList>
                                <TabPanel value={0} className={styles.tabPanel}>
                                    <div className={styles.filesContainer}>
                                        <ImageLightbox slides={[{ src: inspection.registration_card.file }]}>
                                            <img src={inspection.registration_card.file} alt="RegistrationCard"/>
                                        </ImageLightbox>
                                        <ImageLightbox slides={[{ src: inspection.odometer.file }]}>
                                            <img src={inspection.odometer.file} alt="Odometer"/>
                                        </ImageLightbox>
                                    </div>
                                </TabPanel>
                                <TabPanel value={1} className={styles.tabPanel}>
                                    <div className={styles.filesContainer}>
                                        <video controls playsInline>
                                            <source src={inspection.horn.file}/>
                                        </video>
                                        <ImageLightbox slides={[{ src: inspection.interior_driver_side.file }]}>
                                            <img src={inspection.interior_driver_side.file} alt="InteriorDriverSide"/>
                                        </ImageLightbox>
                                        <ImageLightbox slides={[{ src: inspection.driver_seat_adjustment.file }]}>
                                            <img src={inspection.driver_seat_adjustment.file}
                                                 alt="DriverSeatAdjustment"/>
                                        </ImageLightbox>
                                        <ImageLightbox slides={[{ src: inspection.interior_passenger_side.file }]}>
                                            <img src={inspection.interior_passenger_side.file}
                                                 alt="InteriorPassengerSide"/>
                                        </ImageLightbox>
                                        <ImageLightbox slides={[{ src: inspection.passenger_seat_adjustment.file }]}>
                                            <img src={inspection.passenger_seat_adjustment.file}
                                                 alt="PassengerSeatAdjustment"/>
                                        </ImageLightbox>
                                        <ImageLightbox slides={[{ src: inspection.interior_backseat.file }]}>
                                            <img src={inspection.interior_backseat.file} alt="InteriorBackseat"/>
                                        </ImageLightbox>
                                    </div>
                                </TabPanel>
                                <TabPanel value={2} className={styles.tabPanel}>
                                    <div className={styles.filesContainer}>
                                        <ImageLightbox slides={[{ src: inspection.exterior_left.file }]}>
                                            <img src={inspection.exterior_left.file} alt="ExteriorLeft"/>
                                        </ImageLightbox>
                                        <ImageLightbox slides={[{ src: inspection.exterior_right.file }]}>
                                            <img src={inspection.exterior_right.file} alt="ExteriorRight"/>
                                        </ImageLightbox>
                                        <video controls playsInline>
                                            <source src={inspection.exterior_front.file}/>
                                        </video>
                                        <video controls playsInline>
                                            <source src={inspection.exterior_rear.file}/>
                                        </video>
                                    </div>
                                </TabPanel>
                                <TabPanel value={3} className={styles.tabPanel}>
                                    <div className={styles.filesContainer}>
                                        <ImageLightbox slides={[{ src: inspection.left_front_tire.file }]}>
                                            <img src={inspection.left_front_tire.file} alt="LeftFrontTire"/>
                                        </ImageLightbox>
                                        <ImageLightbox slides={[{ src: inspection.right_front_tire.file }]}>
                                            <img src={inspection.right_front_tire.file} alt="RightFrontTire"/>
                                        </ImageLightbox>
                                        <ImageLightbox slides={[{ src: inspection.left_rear_tire.file }]}>
                                            <img src={inspection.left_rear_tire.file} alt="LeftRearTire"/>
                                        </ImageLightbox>
                                        <ImageLightbox slides={[{ src: inspection.right_rear_tire.file }]}>
                                            <img src={inspection.right_rear_tire.file} alt="RightRearTire"/>
                                        </ImageLightbox>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
                <div className={styles.actions}>
                    <div>
                        {
                            inspection.status === "submitted" &&
                            <GreenRoundedButton onClick={() => startInspection({id: inspection.id})}>Run
                                Inspection</GreenRoundedButton>
                        }
                        {
                            inspection.status === "pending" &&
                            <RedRoundedButton onClick={() => setRejectModalOpen(true)}>Reject Inspection</RedRoundedButton>
                        }
                    </div>
                    <div>
                        {
                            inspection.status === "pending" &&
                            <GreenRoundedButton onClick={() => setAcceptModalOpen(true)}>Accept Inspection</GreenRoundedButton>
                        }
                    </div>
                </div>
            </div>
            <AcceptInspectionPopup open={acceptModalOpen} onClose={() => setAcceptModalOpen(false)}
                                   inspection={inspection}/>
            <RejectInspectionPopup open={rejectModalOpen} onClose={() => setRejectModalOpen(false)}
                                   inspection={inspection}/>
        </>
    );
}