import {FunctionComponent} from "react"

import styles from "./styles.module.css"

interface InspectionHandlerLabelProps {
    processed_by?: string,
}

export const InspectionHandlerLabel: FunctionComponent<InspectionHandlerLabelProps> = ({processed_by}) => {
    if (processed_by) {
        return (
            <div className={styles.label}>
                <span>Processed by</span> <h2>{processed_by}</h2>
            </div>
        )
    }
    return null
}