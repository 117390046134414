import {FunctionComponent, useEffect, useRef, useState} from "react"

import styles from "./styles.module.css"

interface GreenCircularProgressProps {
    value: number,
    size?: "small" | "medium" | "large",
    strokeWidth?: number,
    backgroundColor?: string,
}

const sizesMap = {
    small: 16,
    medium: 24,
    large: 32,
}

export const GreenCircularProgress: FunctionComponent<GreenCircularProgressProps> = ({value, size = "medium", strokeWidth = 4, backgroundColor = "transparent"}) => {
    const [offset, setOffset] = useState<number>(0);
    const circleRef = useRef<SVGCircleElement>(null);

    const mappedSize = sizesMap[size]

    const center = mappedSize / 2;
    const radius = mappedSize / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;

    useEffect(() => {
        const progressOffset = ((100 - value) / 100) * circumference;
        setOffset(progressOffset);
        if (circleRef.current) {
            (circleRef.current as SVGCircleElement).style.cssText = 'transition: stroke-dashoffset 50ms ease-in-out;';
        }
    }, [circumference, value]);

    return (
        <svg className={styles.svg} width={mappedSize} height={mappedSize}>
                <circle
                    className={styles.background}
                    fill={backgroundColor}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                    stroke="transparent"
                />
                <circle
                    className={styles.progress}
                    fill={backgroundColor}
                    ref={circleRef}
                    stroke="#09a8a6"
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                />
        </svg>
    )
}