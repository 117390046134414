import {FunctionComponent, useEffect} from "react"

import styles from "./styles.module.css"
import {AdminHeader} from "@components/headers/AdminHeader/AdminHeader";
import {Outlet, useLocation} from "react-router-dom";
import {AdminSidebar} from "@components/sidebars/AdminSidebar/AdminSidebar";
import {PermittedComponent} from "@helpers/components/PermittedComponent/PermittedComponent";
import {InspectorSidebar} from "@components/sidebars/InspectorSidebar/InspectorSidebar";

export const AdminLayout: FunctionComponent = () => {
    const location = useLocation()

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [location.key])

    return (
        <div className={styles.layout}>
            <div className={styles.sidebarContainer}>
                <PermittedComponent allowedRoles={["super_admin", "admin"]}>
                    <AdminSidebar/>
                </PermittedComponent>
                <PermittedComponent allowedRoles={["inspector"]}>
                    <InspectorSidebar/>
                </PermittedComponent>
            </div>
            <div/>
            <div className={styles.main}>
                <div className={styles.header}>
                    <AdminHeader/>
                </div>
                <div className={styles.content}>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}