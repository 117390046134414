import {AuthToken, User} from "@appTypes/user"
import {createSlice} from "@reduxjs/toolkit";
import {authApi} from "@features/auth/api";
import {googleOauthApi} from "@features/oauth/google/api";
import {googleTwoFactorAuthApi} from "@features/two_factor_auth/google/api";

type authReducerState = {
    user?: User | null,
    token?: AuthToken | null
}

const initialState: authReducerState = {
    user: null,
    token: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setToken: (state, action) => {
            const {token} = action.payload

            state.token = token
        },
        setUser: (state, action) => {
            const {user} = action.payload

            state.user = user
        },
        logout: (state) => {
            state.token = null
            state.user = null
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(authApi.endpoints.signIn.matchFulfilled, (state, {payload}) => {
            state.token = payload.token
            state.user = payload.user
        })
        builder.addMatcher(authApi.endpoints.signUp.matchFulfilled, (state, {payload}) => {
            state.token = payload.token
            state.user = payload.user
        })
        builder.addMatcher(authApi.endpoints.getMyProfile.matchFulfilled, (state, {payload}) => {
            state.user = payload.user
        })
        builder.addMatcher(googleOauthApi.endpoints.signOnWithGoogle.matchFulfilled, (state, {payload}) => {
            if (payload.context === "sign_in") {
                state.token = payload.token
                state.user = payload.user
            }
        })
        builder.addMatcher(googleOauthApi.endpoints.finishSignUpWithGoogle.matchFulfilled, (state, {payload}) => {
            state.token = payload.token
            state.user = payload.user
        })
        builder.addMatcher(googleTwoFactorAuthApi.endpoints.connectVerifyGoogleTwoFactorAuth.matchFulfilled, (state, {payload}) => {
            state.user = payload.user
            state.token = payload.token
        })
        builder.addMatcher(googleTwoFactorAuthApi.endpoints.verifyGoogleTwoFactorAuth.matchFulfilled, (state, {payload}) => {
            state.user = payload.user
            state.token = payload.token
        })
        builder.addMatcher(googleTwoFactorAuthApi.endpoints.disconnectGoogleTwoFactorAuth.matchFulfilled, (state, {payload}) => {
            state.user = payload.user
            state.token = payload.token
        })
    }
})

export const {setToken, setUser, logout} = authSlice.actions

export const reducer = authSlice.reducer

export const getMe = (state: {auth:  authReducerState }) => state.auth.user
export const getMyToken = (state: {auth: authReducerState}) => state.auth.token