import {AxiosRequestConfig, AxiosProgressEvent, AxiosHeaders} from "axios"
import {useState} from "react";
import axios from "axios";
import {useAppSelector} from "@features/redux/store";
import {getMyToken} from "@features/auth/slice";

export const useUploadProgress = () => {
    const token = useAppSelector(getMyToken)

    const [loadingProgress, setLoadingProgress] = useState<number>(0)
    const [isLoading, setLoading] = useState<boolean>(false)

    const headers = new AxiosHeaders({
        Authorization: `Token ${token?.key}`
    })

    const config: AxiosRequestConfig = {
        onUploadProgress: (event: AxiosProgressEvent) => {
            const progress = Math.round((event.loaded * 100) / (event?.total ?? 1));
            setLoadingProgress(progress)
        },
        headers: headers
    };
    const upload = async (url: string, formData: FormData) => {
        setLoading(true)

        try {
            return await axios.post(url, formData, config);
        } finally {
            setLoading(false);
            setLoadingProgress(0);
        }
    }

    return {
        loadingProgress,
        isLoading,
        upload
    }
}