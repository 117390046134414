import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {useSignInMutation} from "@features/auth/api";
import {Form, Formik, FormikErrors, FormikHelpers} from "formik";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";
import {NonFieldErrors} from "@components/common/NonFieldErrors/NonFieldErrors";
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {Link, useNavigate} from "react-router-dom";
import {signInFormValidationSchema} from "@helpers/validationSchemas/auth";
import {useTranslatedSchema} from "@helpers/hooks/useTranslatedSchema";
import {GoogleAuthButton} from "@components/common/buttons/GoogleAuthButton/GoogleAuthButton";
import {useSignOnWithGoogleMutation} from "@features/oauth/google/api";
import {TokenResponse} from "@react-oauth/google";
import {useTranslation} from "react-i18next";

type Values = {
    email: string,
    password: string,
    user_agreement: boolean,
}

const initialValues: Values = {
    email: "",
    password: "",
    user_agreement: false,
}

export const SignIn: FunctionComponent = () => {
    const navigate = useNavigate()

    const {t} = useTranslation()

    const [signIn, {isLoading}] = useSignInMutation()

    const [signOnWithGoogle] = useSignOnWithGoogleMutation()

    const schema = useTranslatedSchema(signInFormValidationSchema)

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        try {
            await signIn(values).unwrap()

            setSubmitting(false)
        } catch (e) {
            setSubmitting(false)
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)
                setErrors(errors.errors as FormikErrors<Values>)
            }
        }
    }

    const signOnWithGoogleHandler = async ({access_token}: TokenResponse, setErrors?: FormikHelpers<unknown>['setErrors']) => {
        try {
            const data = await signOnWithGoogle({access_token}).unwrap()

            if (data.context === "sign_up") {
                navigate("/sign_up/finish", {
                    state: {
                        email: data.user.email,
                        first_name: data.user.first_name,
                        last_name: data.user.last_name,
                        phone_number: data.user.phone_number,
                    }
                })
            }
        } catch (e) {
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)

                if (setErrors) {
                    setErrors(errors.errors)
                }
            } else {
                console.log(e)
            }
        }
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
            {({isSubmitting, isValid}) => (
                <Form className={styles.form}>
                    <NonFieldErrors/>
                    <WhiteInput name="email" label={t("user:SignIn.Form.EmailInput.label")} type="email"
                                placeholder={t("user:SignIn.Form.EmailInput.placeholder") as string}/>
                    <WhiteInput name="password" label={t("user:SignIn.Form.PasswordInput.label")} type="password"
                                placeholder={t("user:SignIn.Form.PasswordInput.placeholder") as string}/>
                    <div className={styles.submit}>
                        <GreenRoundedButton type="submit" disabled={isSubmitting || !isValid || isLoading}>{t("user:SignIn.Form.SubmitButton")}</GreenRoundedButton>
                        <Link to="/forgot-password" className={styles.forgotPasswordLink}>
                            {t("user:SignIn.Form.forgotPassword")}
                        </Link>
                    </div>

                    <div className={styles.description}>
                        <span>{t("user:SignIn.Form.description.preamble")}</span>
                        <Link to="/sign_up">
                            {t("user:SignIn.Form.description.link")}
                        </Link>
                    </div>
                    <div className={styles.separator}>
                        <hr/>
                        <span>{t("user:SignIn.Form.separator")}</span>
                        <hr/>
                    </div>
                    <GoogleAuthButton className={styles.googleLoginButton} onSuccess={signOnWithGoogleHandler}/>
                </Form>
            )}
        </Formik>
    )
}