import {FunctionComponent, Suspense} from "react"

import styles from "./styles.module.css"
import SignInBackground from "@assets/media/backgrounds/SignInBackground.png";
import {SignUp as Form} from "@components/user/forms/SignUp/SignUp"
import {useTranslation} from "react-i18next";

export const SignUp: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.page}>
            <div className={styles.background}>
                <img src={SignInBackground} alt="SignInBackground"/>
            </div>
            <div className={styles.content}>
                <div>
                    <h1 className={styles.title}>
                        {t("user:SignUp.title")}
                    </h1>
                    <p className={styles.description}>
                        {t("user:SignUp.description")}
                    </p>
                    <Suspense fallback="Loading...">
                        <Form/>
                    </Suspense>
                </div>
            </div>
        </div>
    )
}