import {styled} from "@mui/system";
import {Tab, tabClasses} from "@mui/base";

export const GreenTab = styled(Tab)`
  appearance: none;
  border: none;

  padding: 1em;

  color: white;

  background-color: #09a8a6;

  font-weight: bold;

  //border-right: 2px solid #055e5d;
  border-right: 2px solid #089694;

  &:hover {
    background-color: #09bebc;
  }

  &:active {
    background-color: #055e5d;
  }

  &:first-of-type {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }

  &:last-of-type {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;

    border-right: none;
  }

  &.${tabClasses.selected} {
    background-color: #055e5d;
  }

  &:has(+ &.${tabClasses.selected}), 
  &.${tabClasses.selected} {
    border-right: none;
  }
`