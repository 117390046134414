import {ChangeEvent, FunctionComponent, HTMLAttributes} from "react"

import styles from "./styles.module.css"
import SearchIcon from '@mui/icons-material/Search';
import SegmentIcon from '@mui/icons-material/Segment';

interface WhiteSearchInputProps {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    value: string,
    placeholder?: string,
    className?: string,
}

export const WhiteSearchInput: FunctionComponent<WhiteSearchInputProps & Omit<HTMLAttributes<HTMLInputElement>, 'ref'>> = ({onChange, value, placeholder = "Search", className = "", ...inputProps}) => {
    return (
        <label className={styles.label}>
            <div className={styles.inputBox}>
                <SearchIcon color="inherit"/>
                <input {...inputProps} onChange={onChange} value={value} className={`${className} ${styles.input}`} placeholder={placeholder} type="search"/>
                <SegmentIcon color="inherit"/>
            </div>
            <SearchIcon/>
        </label>
    )
}
