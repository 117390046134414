import {FunctionComponent, useState} from "react"

import styles from "./styles.module.css"
import {Form, Formik, FormikErrors, FormikHelpers} from "formik";
import {forgotPasswordSchema} from "@helpers/validationSchemas/auth";
import {NonFieldErrors} from "@components/common/NonFieldErrors/NonFieldErrors";
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {useNavigate} from "react-router-dom";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {convertApiFormErrors, isApiFormResponse, isApiResponse} from "@helpers/errors";
import {useTranslatedSchema} from "@helpers/hooks/useTranslatedSchema";
import {useTranslation} from "react-i18next";
import {useForgotPasswordMutation} from "@features/users/api";
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query";
import {GreenButton} from "@components/common/buttons/GreenButton/GreenButton";

interface Values {
    email: string,
}

const initialValues: Values = {
    email: "",
}

export const ForgotPassword: FunctionComponent = () => {
    const navigate = useNavigate()

    const [requestSent, setRequestSent] = useState<boolean>(false);

    const {t} = useTranslation()

    const [forgotPassword, {isLoading}] = useForgotPasswordMutation();

    const schema = useTranslatedSchema(forgotPasswordSchema)

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        try {
            await forgotPassword(values).unwrap();

            setSubmitting(false)
            setRequestSent(true);
            // @ts-ignore
        } catch (e: FetchBaseQueryError) {
            setSubmitting(false)

            if ('originalStatus' in e && e?.originalStatus === 404) {
                setErrors({
                    non_field_errors: "validation:credentials.invalid"
                } as FormikErrors<Values>)
            }

            console.log(e)

            if (isApiResponse(e)) {
                if (e.data.message === "Not found.") {
                    setErrors({
                        non_field_errors: "validation:credentials.invalid"
                    } as FormikErrors<Values>)
                }
            }

            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)
                setErrors(errors.errors as FormikErrors<Values>)
            }
        }
    }

    if (requestSent) {
        return (
            <div className={styles.successWrapper}>
                <p className={styles.description}>
                    {t("user:ForgotPassword.emailSent")}
                </p>
                <GreenButton onClick={() => navigate("/")}>
                    {t("user:ForgotPassword.backToHomePage")}
                </GreenButton>
            </div>
        );
    }

    return (
        <Formik initialValues={initialValues} validationSchema={schema}
                onSubmit={onSubmit} className={styles.form}>
            {({
                  isSubmitting,
                  isValid,
              }) => (
                <Form className={styles.form}>
                    <NonFieldErrors/>
                    <WhiteInput name="email" label={t("user:SignUp.Form.EmailInput.label")} type="email"
                                placeholder={t("user:SignUp.Form.EmailInput.label") as string}/>
                    <div className={styles.submit}>
                        <GreenRoundedButton type="submit" disabled={isSubmitting || !isValid || isLoading}>{t("user:ForgotPassword.submit")}</GreenRoundedButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}