import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {Form, Formik, FormikHelpers} from "formik";
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {useTranslatedSchema} from "@helpers/hooks/useTranslatedSchema";
import {contactUsFormValidationSchema} from "@helpers/validationSchemas/contact-us";
import {WhiteTextArea} from "@components/common/inputs/WhiteTextArea/WhiteTextArea";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {useNewProposalMutation} from "@features/proposals/api";
import {useNavigate} from "react-router-dom";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";
import {useTranslation} from "react-i18next";

interface Values {
    name: string,
    email: string,
    message: string,
}

const initialValues: Values = {
    name: "",
    email: "",
    message: "",
}

export const ContactUs: FunctionComponent = () => {
    const navigate = useNavigate()

    const {t} = useTranslation()

    const schema = useTranslatedSchema(contactUsFormValidationSchema)

    const [sendProposal] = useNewProposalMutation()

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        setSubmitting(true)

        try {
            await sendProposal(values).unwrap()

            setSubmitting(false)

            navigate("/")
        }
        catch (e) {
            setSubmitting(false)

            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)

                setErrors(errors.errors)
            }
        }
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
            <Form className={styles.form}>
                <div className={styles.fields}>
                    <WhiteInput label={t("user:ContactUs.form.NameInput.label")} name="name" placeholder={t("user:ContactUs.form.NameInput.placeholder") as string}/>
                    <WhiteInput label={t("user:ContactUs.form.EmailInput.label")} name="email" placeholder={t("user:ContactUs.form.EmailInput.placeholder") as string}/>
                    <WhiteTextArea label={t("user:ContactUs.form.MessageInput.label")} name="message" placeholder={t("user:ContactUs.form.MessageInput.placeholder") as string}/>
                </div>
                <div className={styles.submitButton}>
                    <GreenRoundedButton type="submit">
                        {t("user:ContactUs.form.SubmitButton")}
                    </GreenRoundedButton>
                </div>
            </Form>
        </Formik>
    )
}