import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {PrimaryAccordion} from "@components/common/accordions/PrimaryAccordion/PrimaryAccordion";
import {useTranslation} from "react-i18next";

export const FrequentlyAskedQuestionsSection: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.section}>
            <h2 className={styles.title}>{t("user:Home.FrequentlyAskedQuestionsSection.title")}</h2>
            <div className={styles.content}>
                {(t("user:Home.FrequentlyAskedQuestionsSection.questions", {returnObjects: true}) as unknown as {title: string, answer: string}[]).map(question => (
                    <PrimaryAccordion>
                        <PrimaryAccordion.Header>
                            <>{question.title}</>
                        </PrimaryAccordion.Header>
                        <PrimaryAccordion.Body>
                            <>
                            {question.answer.split('\n').map(chunk => (
                                <>
                                    {chunk}
                                    <br/>
                                </>
                            ))}
                            </>
                        </PrimaryAccordion.Body>
                    </PrimaryAccordion>
                ))}
            </div>
        </div>
    )
}