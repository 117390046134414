import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {useAppDispatch, useAppSelector} from "@features/redux/store";
import {getMe, logout} from "@features/auth/slice";
import {capitalizeString} from "@helpers/string";
import {NavLink} from "react-router-dom";

import WhiteLogo from "@assets/icons/WhiteLogo.svg"
import RequestsIcon from '@mui/icons-material/DirectionsCar';
import UsersIcon from '@mui/icons-material/Person';
import ArchiveIcon from '@mui/icons-material/Archive';
import ProposalsIcon from '@mui/icons-material/Contactless';
import BlogIcon from '@mui/icons-material/Feed';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { googleLogout } from "@react-oauth/google";

interface AdminSidebarProps {
    className?: string,
}

export const AdminSidebar: FunctionComponent<AdminSidebarProps> = ({className = ""}) => {
    const dispatch = useAppDispatch()

    const user = useAppSelector(getMe)

    const logoutMe = () => {
        dispatch(logout())

        googleLogout()
    }

    return (
        <div className={`${styles.sidebar} ${className}`}>
            <h2 className={styles.roleName}>
                {capitalizeString(user?.role.name.replaceAll('_', ' ') || "")}
            </h2>
            <div className={styles.mainMenu}>
                <NavLink to="requests" className={({isActive}) => isActive ? styles.active : ""}>
                    <RequestsIcon/> Requests
                </NavLink>
                <NavLink to="users" className={({isActive}) => isActive ? styles.active : ""}>
                    <UsersIcon/> Users
                </NavLink>
                <NavLink to="archive" className={({isActive}) => isActive ? styles.active : ""}>
                    <ArchiveIcon/> Archive
                </NavLink>
                <NavLink to="proposals" className={({isActive}) => isActive ? styles.active : ""}>
                    <ProposalsIcon/> Proposals
                </NavLink>
                {/*<NavLink to="blog" className={({isActive}) => isActive ? styles.active : ""}>*/}
                {/*    <BlogIcon/> Blog*/}
                {/*</NavLink>*/}
            </div>
            <div className={styles.miscellaneous}>
                <div className={styles.bottomMenu}>
                    <div>
                        <NavLink to="settings" className={({isActive}) => isActive ? styles.active : ""}>
                            <SettingsIcon/> Settings
                        </NavLink>
                    </div>
                    <div>
                        <NavLink to="#" onClick={logoutMe}>
                            <LogoutIcon/> Logout
                        </NavLink>
                    </div>
                </div>
                <div className={styles.logo}>
                    <img src={WhiteLogo} alt="Logo"/>
                </div>
            </div>
        </div>
    )
}