import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from '@components/App/App';
import {I18nextProvider} from "react-i18next";
import i18next from "@features/i18n";
import {persistor, store} from "@features/redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import {Provider} from "react-redux";
import { GoogleOAuthProvider } from '@react-oauth/google';
import {LoadingScreen} from "@screens/LoadingScreen/LoadingScreen";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={"loading..."}>
                <I18nextProvider i18n={i18next}>
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID!}>
                        <Suspense fallback={<LoadingScreen/>}>
                            <App/>
                        </Suspense>
                    </GoogleOAuthProvider>
                </I18nextProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
