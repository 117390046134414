import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {useInspectionCheckoutQuery} from "@features/inspection/api";
import {useCompaniesListQuery} from "@features/companies/api";
import {useTranslation} from "react-i18next";

interface CheckoutCompaniesProps {
    inspection_companies: number[]
}

export const CheckoutCompanies: FunctionComponent<CheckoutCompaniesProps> = ({inspection_companies}) => {
    const {data: checkout} = useInspectionCheckoutQuery({inspection_companies})
    const {data: selected_companies} = useCompaniesListQuery({id__in: inspection_companies})

    const {t} = useTranslation()

    if(!inspection_companies.length) {
        return null
    }

    return (
        <div className={styles.container}>
            <h4 className={styles.title}>
                {t("user:NewInspection.Checkout.CheckoutCompanies.title")}
            </h4>
            <div className={styles.card}>
                <p className={styles.label}>
                    {t("user:NewInspection.Checkout.CheckoutCompanies.CompaniesSelectedLabel")}
                </p>
                <div className={styles.listContainer}>
                    <div className={styles.list}>
                        {selected_companies?.map((company, idx) => (
                            <div className={styles.companyLogo} key={idx}>
                                <img src={company.logo} alt={company.name} key={idx}/>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.total}>
                    <p className={styles.label}>
                        {t("user:NewInspection.Checkout.CheckoutCompanies.TotalLabel")}
                    </p>
                    <h3 className={styles.value}>
                        {checkout?.total}$
                    </h3>
                </div>
            </div>
        </div>
    )
}