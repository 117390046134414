import {api} from "@features/redux/api";
import {AuthToken, User} from "@appTypes/user";

type userSignUpDataType = {
    first_name: string,
    last_name: string,
    email: string,
    phone_number: string,
    password: string
    password_confirmation: string
}

type userSignInDataType = Pick<userSignUpDataType, "email" | "password">

type userAuthResultType = {
    user: User,
    token: AuthToken,
}

type userProfileType = Pick<userAuthResultType, "user">

export const authApi = api.injectEndpoints({
    endpoints: builder => ({
        signIn: builder.mutation<userAuthResultType, userSignInDataType>({
            query: (credentials) => ({
                url: 'user/sign_in',
                method: 'POST',
                body: {...credentials}
            })
        }),
        signUp: builder.mutation<userAuthResultType, userSignUpDataType>({
            query: (credentials) => ({
                url: 'user/sign_up',
                method: 'POST',
                body: {...credentials}
            })
        }),
        getMyProfile: builder.query<userProfileType, void>({
            query: () => ({
                url: 'user/me',
                method: 'GET'
            })
        })
    })
})

export const {
    useSignInMutation,
    useSignUpMutation,
    useGetMyProfileQuery
} = authApi
