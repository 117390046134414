import {FunctionComponent, Suspense} from "react"

import styles from "./styles.module.css"

import {CreateUser as CreateUserForm} from "@components/admin/forms/CreateUser/CreateUser"

export const CreateUser: FunctionComponent = () => {
    return (
        <>
            <div className={styles.page}>
                <div className={styles.title}>
                    <h2>Account details</h2>
                    <p>
                        Amet malesuada facilisis pretium tincidunt iaculis et.
                    </p>
                </div>
                <Suspense fallback="loading...">
                    <CreateUserForm/>
                </Suspense>
            </div>
        </>
    )
}
