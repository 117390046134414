import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {useGetMainPageVideoQuery} from "@features/core/api";
import {Skeleton} from "@mui/material";

export const TimingSection: FunctionComponent = () => {
    const {data, isLoading} = useGetMainPageVideoQuery();

    if (isLoading) {
        return (
            <div className={styles.section}>
                <Skeleton width="100%" height="100%" style={{aspectRatio: "16 / 9"}}/>
            </div>
        );
    }

    if (!data || !data.length) {
        return null;
    }

    if (data[0].hidden) {
        return null;
    }

    const autoPlay = data[0].auto_play;

    return (
        <div className={styles.section}>
            <iframe
                src={`${data[0].video_url}?autoplay=${+autoPlay}${autoPlay ? "&mute=1" : ""}&rel=0`}
                frameBorder="0"
                allow="autoplay; encrypted-media;"
                allowFullScreen
                title="Embedded youtube"
                name="youtube embed"
            />
        </div>
    )
}