import {FunctionComponent, MouseEvent, ReactNode} from "react"

import styles from "./styles.module.css"
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";

interface InspectionHintsContainerProps {
    onHintAccept: (() => void) | ((event: MouseEvent<HTMLButtonElement>) => void),
    children: ReactNode,
}

export const InspectionHintsContainer: FunctionComponent<InspectionHintsContainerProps> = ({onHintAccept, children}) => {
    return (
        <div className={styles.hints}>
            {children}
            <GreenRoundedButton onClick={onHintAccept}>
                Continue
            </GreenRoundedButton>
        </div>
    )
}