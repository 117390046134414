import {FunctionComponent, useState} from "react"

import styles from "./styles.module.css"
import {Form, Formik, FormikErrors, FormikHelpers} from "formik";
import {signUpFormValidationSchema} from "@helpers/validationSchemas/auth";
import {NonFieldErrors} from "@components/common/NonFieldErrors/NonFieldErrors";
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {WhiteCheckbox} from "@components/common/checkboxes/WhiteCheckbox/WhiteCheckbox";
import {Link, useNavigate} from "react-router-dom";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";
import {useSignUpMutation} from "@features/auth/api";
import {useTranslatedSchema} from "@helpers/hooks/useTranslatedSchema";
import {GoogleAuthButton} from "@components/common/buttons/GoogleAuthButton/GoogleAuthButton";
import {useSignOnWithGoogleMutation} from "@features/oauth/google/api";
import {TokenResponse} from "@react-oauth/google";
import {TermsOfUsePopup} from "@components/user/popups/TermsOfUsePopup/TermsOfUsePopup";
import {useTranslation} from "react-i18next";

interface Values {
    first_name: string,
    last_name: string,
    email: string,
    phone_number: string,
    password: string,
    password_confirmation: string,
    user_agreement: boolean,
}

const initialValues: Values = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "+1",
    password: "",
    password_confirmation: "",
    user_agreement: false,
}

export const SignUp: FunctionComponent = () => {
    const navigate = useNavigate()

    const {t} = useTranslation()

    const [signUp, {isLoading}] = useSignUpMutation()

    const [signOnWithGoogle] = useSignOnWithGoogleMutation()

    const [isUserAgreementModalOpen, setUserAgreementModalOpen] = useState<boolean>(false)

    const schema = useTranslatedSchema(signUpFormValidationSchema)

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        try {
            await signUp(values).unwrap()

            setSubmitting(false)
        } catch (e) {
            setSubmitting(false)
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)
                setErrors(errors.errors as FormikErrors<Values>)
            }
        }
    }

    const signOnWithGoogleHandler = async ({access_token}: TokenResponse, setErrors?: FormikHelpers<unknown>["setErrors"]) => {
        try {
            const data = await signOnWithGoogle({access_token}).unwrap()

            if (data.context === "sign_up") {
                navigate("/sign_up/finish", {
                    state: {
                        email: data.user.email,
                        first_name: data.user.first_name,
                        last_name: data.user.last_name,
                    }
                })
            }
        }
        catch (e) {
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)

                if (setErrors) {
                    setErrors(errors.errors)
                }
            }
            else {
                console.log(e)
            }
        }
    }

    return (
        <>
            <Formik initialValues={initialValues} validationSchema={schema}
                    onSubmit={onSubmit} className={styles.form}>
                {({
                      isSubmitting,
                      isValid,
                  }) => (
                    <Form className={styles.form}>
                        <NonFieldErrors/>
                        <WhiteInput name="first_name" label={t("user:SignUp.Form.FirstNameInput.label")}
                                    placeholder={t("user:SignUp.Form.FirstNameInput.label") as string}/>
                        <WhiteInput name="last_name" label={t("user:SignUp.Form.LastNameInput.label")}
                                    placeholder={t("user:SignUp.Form.LastNameInput.label") as string}/>
                        <WhiteInput name="email" label={t("user:SignUp.Form.EmailInput.label")} type="email"
                                    placeholder={t("user:SignUp.Form.EmailInput.label") as string}/>
                        <WhiteInput name="phone_number" label={t("user:SignUp.Form.PhoneNumberInput.label")} type="tel"
                                    placeholder={t("user:SignUp.Form.PhoneNumberInput.label") as string}/>
                        <WhiteInput name="password" label={t("user:SignUp.Form.PasswordInput.label")} type="password"
                                    placeholder={t("user:SignUp.Form.PasswordInput.label") as string}/>
                        <WhiteInput name="password_confirmation" label={t("user:SignUp.Form.PasswordConfirmationInput.label")} type="password"
                                    placeholder={t("user:SignUp.Form.PasswordConfirmationInput.label") as string}/>
                        <WhiteCheckbox label={<span>{t("user:SignUp.Form.UserAgreement.label")} <Link to="#" className={styles.link} onClick={() => setUserAgreementModalOpen(true)}>{t("user:SignUp.Form.UserAgreement.link")}</Link></span>} name="user_agreement"/>
                        <div className={styles.submit}>
                            <GreenRoundedButton type="submit" disabled={isSubmitting || !isValid || isLoading}>{t("user:SignUp.Form.SubmitButton")}</GreenRoundedButton>
                        </div>
                        <div className={styles.description}>
                            <span>{t("user:SignUp.Form.description.preamble")}</span>
                            <Link to="/sign_in" className={styles.link}>
                                {t("user:SignUp.Form.description.link")}
                            </Link>
                        </div>
                        <div className={styles.separator}>
                            <hr/>
                            <span>{t("user:SignUp.Form.separator")}</span>
                            <hr/>
                        </div>
                        <GoogleAuthButton onSuccess={signOnWithGoogleHandler} className={styles.googleLoginButton}/>
                    </Form>
                )}
            </Formik>
            <TermsOfUsePopup open={isUserAgreementModalOpen} onClose={() => setUserAgreementModalOpen(false)}/>
        </>
    )
}