import {FunctionComponent, MouseEvent, useState} from "react"

import styles from "./styles.module.css"
import {useCompaniesListQuery} from "@features/companies/api";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {TransparentButton} from "@components/common/buttons/TransparentButton/TransparentButton";

export const CompaniesCarousel: FunctionComponent = () => {
    const {data: companies} = useCompaniesListQuery()

    if (!companies) {
        return null
    }

    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 3000},
            items: 5
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 4
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 3
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 2
        }
    };

    return (
        <div className={styles.container}>
            <Carousel
                responsive={responsive}
                arrows={false}
                keyBoardControl={false}
                infinite
                autoPlay
                autoPlaySpeed={3000}
                customButtonGroup={<ButtonGroup/>}
                renderButtonGroupOutside
                itemClass={styles.slideWrapper}
                className={styles.slider}
                customTransition="transform 300ms linear"
                sliderClass={styles.sliderTrack}
            >
                {
                    companies.map((company, idx) => (
                        <div className={styles.slide} key={idx}>
                            <img src={company.logo} alt={company.name}/>
                        </div>
                    ))
                }
            </Carousel>
        </div>
    )
}

const ButtonGroup: FunctionComponent<{next?: () => void, previous?: () => void}> = ({next, previous}) => (
    <>
        <LeftArrow onClick={() => previous && previous()}/>
        <RightArrow onClick={() => next && next()}/>
    </>
)

const LeftArrow: FunctionComponent<{ onClick?: (event: MouseEvent<HTMLElement>) => void }> = ({onClick}) => (
    <TransparentButton className={styles.prevButton} onClick={onClick}>
        <ChevronLeft color="inherit" fontSize="inherit"/>
    </TransparentButton>
)

const RightArrow: FunctionComponent<{ onClick?: (event: MouseEvent<HTMLElement>) => void }> = ({onClick}) => (
    <TransparentButton className={styles.nextButton} onClick={onClick}>
        <ChevronRight color="inherit" fontSize="inherit"/>
    </TransparentButton>
)
