import {ChangeEvent, FunctionComponent, useCallback, useState} from "react"

import styles from "./styles.module.css"
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {WhiteSelectInput} from "@components/common/inputs/WhiteSelectInput/WhiteSelectInput";
import {useCitiesListQuery, useCountriesListQuery, useRegionsListQuery} from "@features/states/api";
import {Form, Formik, FormikHelpers} from "formik";
import {WhiteSelectCompanyInput} from "@components/common/inputs/WhiteSelectCompanyInput/WhiteSelectCompanyInput";
import {useCompaniesListQuery} from "@features/companies/api";
import {useTranslation} from "react-i18next";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {getFormData, getFormValues, setFormData} from "@helpers/multiStepForm";
import {NEW_INSPECTION_FORM_DATA_KEY} from "@helpers/constants/inspection";
import {useLocation, useNavigate} from "react-router-dom";
import {generalDataFormValidationSchema} from "@helpers/validationSchemas/new-inspection";
import {InspectionCostCard} from "@components/user/InspectionCostCard/InspectionCostCard";
import {useInspectionPriceQuery} from "@features/inspection/api";
import {GreenRadioButton} from "@components/common/radioButtons/GreenRadioButton/GreenRadioButton";
import {InputError} from "@components/common/InputError/InputError";

interface GeneralDataProps {
    title?: string,
    description?: string,
}

interface Values {
    license_plate_number: string,
    applicant_drivers_license: string,
    inspection_country?: number,
    inspection_region?: number,
    inspection_city?: number,
    inspection_companies: number[],
    has_renewed_tires?: boolean | null,
    has_renewed_battery?: boolean | null,
    is_battery_voltage_high_enough?: boolean | null,
}

const TURO_ID = 3;

export const GeneralData: FunctionComponent<GeneralDataProps> = () => {
    const initialValues: Values = {
        license_plate_number: "",
        applicant_drivers_license: "",
        inspection_country: undefined,
        inspection_region: undefined,
        inspection_city: undefined,
        inspection_companies: [],
        ...getFormValues(NEW_INSPECTION_FORM_DATA_KEY, 'license_plate_number', 'inspection_country', 'inspection_region', 'inspection_companies', 'applicant_drivers_license'),
    }

    const navigate = useNavigate()
    const location = useLocation()

    const {t} = useTranslation()

    const [inspectionRegion, setInspectionRegion] = useState<number | undefined>(initialValues.inspection_region)
    const [inspectionCountry, setInspectionCountry] = useState<number | undefined>(initialValues.inspection_country)
    const [inspectionCity, setInspectionCity] = useState<number | undefined>(initialValues.inspection_city);

    const {data: inspection_countries, isFetching: isCountriesLoading} = useCountriesListQuery();
    const {data: inspection_regions, isFetching: isRegionsLoading} = useRegionsListQuery({country: inspectionCountry}, {
        refetchOnMountOrArgChange: true
    });
    const {data: inspection_cities, isFetching: isCitiesLoading} = useCitiesListQuery({region: inspectionRegion}, {
        refetchOnMountOrArgChange: true
    });
    const {data: inspection_prices} = useInspectionPriceQuery()
    const {data: companies} = useCompaniesListQuery({
        regions__id: inspectionRegion
    }, {
        refetchOnMountOrArgChange: true
    })

    const onInspectionCityChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setInspectionCity(parseInt(event.target.value));
    }

    const onInspectionStateChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setInspectionRegion(parseInt(event.target.value));
        setInspectionCity(undefined);
    }

    const onInspectionCountryChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setInspectionCountry(parseInt(event.target.value));
        setInspectionRegion(undefined);
        setInspectionCity(undefined);
    }

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        setFormData(NEW_INSPECTION_FORM_DATA_KEY, {
            ...getFormData(NEW_INSPECTION_FORM_DATA_KEY),
            ...values
        })

        navigate(location, {
            ...location,
            state: {
                activeStep: 1,
            },
        })
        setSubmitting(false)
    }

    return (
        <div className={styles.step}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}
                    validationSchema={generalDataFormValidationSchema}>
                {({
                      isSubmitting,
                      errors,
                      values,
                  }) => (
                    <Form className={styles.form}>
                        <WhiteInput name="license_plate_number"
                                    label={t("user:NewInspection.GeneralData.LicensePlateInput.label")}/>
                        <WhiteInput name="applicant_drivers_license" label={t("user:NewInspection.GeneralData.ApplicantDriversLicense.label")}/>
                        <WhiteSelectInput name="inspection_country"
                                          label={t("user:NewInspection.GeneralData.InspectionCountryInput.label")}
                                          onChange={onInspectionCountryChange} noValueOption>
                            {inspection_countries?.map((obj, idx) => <option value={obj.id} key={idx}>{obj.name}</option>)}
                        </WhiteSelectInput>
                        {
                            !!inspectionCountry && !isCountriesLoading &&
                            <WhiteSelectInput name="inspection_region"
                                              label={t("user:NewInspection.GeneralData.InspectionStateInput.label")}
                                              onChange={onInspectionStateChange} noValueOption>
                                {inspection_regions?.map((obj, idx) => <option value={obj.id} key={idx}>{obj.name}</option>)}
                            </WhiteSelectInput>
                        }
                        {
                            !!inspectionCountry && !isCountriesLoading && !!inspectionRegion && !isRegionsLoading && !!inspection_cities?.length &&
                            <WhiteSelectInput name="inspection_city"
                                              label={t("user:NewInspection.GeneralData.InspectionCityInput.label")}
                                              onChange={onInspectionCityChange}>
                                {inspection_cities?.map((obj, idx) => <option value={obj.id} key={idx}>{obj.name}</option>)}
                                <option>In other city</option>
                            </WhiteSelectInput>
                        }
                        {
                            !!inspectionRegion && !isRegionsLoading && !isCountriesLoading && !isCitiesLoading &&
                            <WhiteSelectCompanyInput
                                name="inspection_companies"
                                label={t("user:NewInspection.GeneralData.InspectionCompanySelect.label")}
                                companies={companies}/>
                        }
                        {
                            values.inspection_companies.includes(TURO_ID) &&
                            <>
                                <div>
                                    <p className={styles.label}>
                                        {t("user:NewInspection.GeneralData.HasRenewedTires.label")}
                                    </p>
                                    <InputError error={errors?.has_renewed_tires}/>
                                    <div className={styles.fieldset}>
                                        <GreenRadioButton label="Yes" name="has_renewed_tires" value={1}/>
                                        <GreenRadioButton label="No" name="has_renewed_tires" value={0}/>
                                    </div>
                                </div>
                                <div>
                                    <p className={styles.label}>
                                        {t("user:NewInspection.GeneralData.HasRenewedBattery.label")}
                                    </p>
                                    <div className={styles.fieldset}>
                                        <GreenRadioButton label="Yes" name="has_renewed_battery" value={1}/>
                                        <GreenRadioButton label="No" name="has_renewed_battery" value={0}/>
                                    </div>
                                </div>
                                {
                                    values.has_renewed_battery !== undefined && !values.has_renewed_battery &&
                                    <div>
                                        <p className={styles.label}>
                                            {t("user:NewInspection.GeneralData.IsBatteryVoltageHighEnough.label")}
                                        </p>
                                        <InputError error={errors?.is_battery_voltage_high_enough} touched/>
                                        <div className={styles.fieldset}>
                                            <GreenRadioButton label="Yes" name="is_battery_voltage_high_enough" value={1}/>
                                            <GreenRadioButton label="No" name="is_battery_voltage_high_enough" value={0}/>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        <InspectionCostCard title={t("user:NewInspection.GeneralData.SingleInspectionCostCard.Label")}
                                            description={t("user:NewInspection.GeneralData.SingleInspectionCostCard.Description")}
                                            price={inspection_prices?.single_inspection}/>
                        <InspectionCostCard
                            title={t("user:NewInspection.GeneralData.EachAdditionalInspectionCostCard.Label")}
                            description={t("user:NewInspection.GeneralData.EachAdditionalInspectionCostCard.Description")}
                            price={inspection_prices?.bundle_inspection}/>
                        <div className={styles.submitButton}>
                            <GreenRoundedButton type="submit"
                                                disabled={isSubmitting || !!Object.values(errors).length || isRegionsLoading || isCitiesLoading || isCountriesLoading || !inspectionRegion}>{t("common:Inspection.NextStepButton")}</GreenRoundedButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}