import {FunctionComponent, ReactElement, useEffect, useRef, useState} from "react"

import styles from "./styles.module.css"

export interface BodyProps {
    open?: boolean,
    className?: string,
    wrapperClassName?: string,
    children: ReactElement | ReactElement[],
}

const Body: FunctionComponent<BodyProps> = ({open, className = "", wrapperClassName = "", children}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [height, setHeight] = useState<number>(0)

    useEffect(() => {
        if (!containerRef.current) {
            return
        }

        if (open) {
            setHeight(containerRef.current.scrollHeight)
        }
        else {
            setHeight(0)
        }
    }, [open])

    return (
        <div className={`${styles.body} ${className}`} ref={containerRef} style={{maxHeight: `${height}px`}}>
            <div className={`${styles.wrapper} ${wrapperClassName}`}>
                {children}
            </div>
        </div>
    )
}

Body.displayName = 'BaseAccordionBody'

export {
    Body
}
