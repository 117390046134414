import {api} from "@features/redux/api";

const mailingApi = api.injectEndpoints({
    endpoints: builder => ({
        massMailing: builder.mutation<void, {registered_already_days: number, last_inspection_days: number}>({
            query: ({ registered_already_days, last_inspection_days }) => ({
                url: "admin/users/mass-mailing",
                method: "POST",
                body: {
                    registered_already_days: registered_already_days,
                    last_inspection_days: last_inspection_days,
                }
            })
        })
    })
})

export const {
    useMassMailingMutation,
} = mailingApi;
