import {FunctionComponent, useState} from "react"

import styles from "./styles.module.css"
import {Form, Formik, FormikErrors, FormikHelpers} from "formik";
import {resetPasswordSchema} from "@helpers/validationSchemas/auth";
import {NonFieldErrors} from "@components/common/NonFieldErrors/NonFieldErrors";
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {convertApiFormErrors, isApiFormResponse, isApiResponse} from "@helpers/errors";
import {useTranslatedSchema} from "@helpers/hooks/useTranslatedSchema";
import {useTranslation} from "react-i18next";
import {
    useCheckPasswordResetRequestQuery,
    useResetPasswordMutation
} from "@features/users/api";
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query";
import {GreenButton} from "@components/common/buttons/GreenButton/GreenButton";

interface Values {
    password: string,
    password_confirmation: string,
}

const initialValues: Values = {
    password: "",
    password_confirmation: "",
}

export const ResetPassword: FunctionComponent = () => {
    const navigate = useNavigate()
    const params = useParams();

    const [requestSent, setRequestSent] = useState<boolean>(false);

    const {t} = useTranslation()

    const {isLoading, error} = useCheckPasswordResetRequestQuery({id: params.id || ""});
    const [resetPassword, {isLoading: isResetPasswordLoading}] = useResetPasswordMutation();

    const schema = useTranslatedSchema(resetPasswordSchema);

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        try {
            await resetPassword({...values, id: params.id!}).unwrap();

            setSubmitting(false)
            setRequestSent(true);
            // @ts-ignore
        } catch (e: FetchBaseQueryError) {
            setSubmitting(false)

            if ('originalStatus' in e && e?.originalStatus === 404) {
                setErrors({
                    non_field_errors: "validation:credentials.invalid"
                } as FormikErrors<Values>)
            }

            if (isApiResponse(e)) {
                if (e.data.message === "Not found.") {
                    setErrors({
                        non_field_errors: "validation:credentials.invalid"
                    } as FormikErrors<Values>)
                }
            }

            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)
                setErrors(errors.errors as FormikErrors<Values>)
            }
        }
    }

    if (requestSent) {
        return (
            <div className={styles.successWrapper}>
                <p className={styles.description}>
                    {t("user:ResetPassword.recovered")}
                </p>
                <GreenButton onClick={() => navigate("/sign_in")}>
                    {t("user:ResetPassword.signIn")}
                </GreenButton>
            </div>
        );
    }

    if (!params.id) {
        return (
            <Navigate to="/"/>
        );
    }

    if (isLoading) {
        return <>loading...</>;
    }

    // @ts-ignore
    if (error && 'status' in error && error.status === 404) {
        return (
            <Navigate to="/"/>
        );
    }

    return (
        <Formik initialValues={initialValues} validationSchema={schema}
                onSubmit={onSubmit} className={styles.form}>
            {({
                  isSubmitting,
                  isValid,
              }) => (
                <Form className={styles.form}>
                    <NonFieldErrors/>
                    <WhiteInput name="password" label={t("user:SignUp.Form.PasswordInput.label")} type="password"
                                placeholder={t("user:SignUp.Form.PasswordInput.label") as string}/>
                    <WhiteInput name="password_confirmation" label={t("user:SignUp.Form.PasswordConfirmationInput.label")} type="password"
                                placeholder={t("user:SignUp.Form.PasswordConfirmationInput.label") as string}/>
                    <div className={styles.submit}>
                        <GreenRoundedButton type="submit" disabled={isSubmitting || !isValid || isLoading}>{t("user:ForgotPassword.submit")}</GreenRoundedButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}