import {FunctionComponent, MouseEvent, Suspense} from "react"

import styles from "./styles.module.css"
import {GreenButton} from "@components/common/buttons/GreenButton/GreenButton";

import TopSectionImage from "@assets/media/images/TopSectionImage.svg"
import CarInspectionIcon from "@assets/icons/home/CarInspectionIcon.svg"
import InspectionCertificateIcon from "@assets/icons/home/InspectionCertificateIcon.svg"
import InspectionCheckIcon from "@assets/icons/home/InspectionCheckIcon.svg"
import {useAppSelector} from "@features/redux/store";
import {getMe} from "@features/auth/slice";
import {useNavigate} from "react-router-dom";
import {Image} from "@components/common/images/Image/Image";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import UberLogo from "@assets/icons/companies/UberLogo.svg"
import LyftLogo from "@assets/icons/companies/LyftLogo.svg"
import TuroLogo from "@assets/icons/companies/TuroLogo.svg"
import {useTranslation} from "react-i18next";

export const TopSection: FunctionComponent = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const user = useAppSelector(getMe)

    const getStarted = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.persist()

        const url = user ? "/dashboard" : "/sign_in"

        navigate(url)
    }

    return (
        <div className={styles.section}>
            <div className={styles.content}>
                <div className={styles.mobile}>
                    <Image src={CarInspectionIcon} alt={CarInspectionIcon} skeletonWidth={48} skeletonHeight={48}/>
                    <Image src={InspectionCheckIcon} alt={InspectionCheckIcon} skeletonWidth={48} skeletonHeight={48}/>
                    <Image src={InspectionCertificateIcon} alt={InspectionCertificateIcon} skeletonWidth={48} skeletonHeight={48}/>
                </div>
                <div>
                    <div className={styles.titleWrapper}>
                        <h1 className={styles.title}>
                            <span style={{color: "#09A9A7", whiteSpace: "pre"}}>
                                {t("user:Home:TopSection.heading", [<br/>])}
                            </span>
                        </h1>
                        <p className={styles.subTitle}>
                            {t("user:Home:TopSection.subHeading")}
                        </p>
                        <div className={styles.companies}>
                            <img src={UberLogo} alt="UberLogo"/>
                            <img src={LyftLogo} alt="LyftLogo"/>
                            <img src={TuroLogo} alt="TuroLogo"/>
                        </div>
                        <p className={styles.subTitle}>
                            {t("user:Home:TopSection.summary")}
                        </p>
                    </div>
                    <div className={styles.getStartedButtonWrapper}>
                        <GreenButton className={styles.getStartedButton} onClick={getStarted}>
                            {t("user:Home:TopSection.getStartedButton")}
                        </GreenButton>
                    </div>
                </div>
                <div className={styles.desktop}>
                    <Image src={TopSectionImage} alt={"TopSectionImage"} skeletonHeight="calc(500px + 7vw)"/>
                </div>
            </div>
            <ExpandMoreIcon className={styles.expandIcon}/>
        </div>
    )
}