import {FunctionComponent} from "react"

import SupportSectionImage from "@assets/media/images/SupportSectionImage.png"

import {Image} from "@components/common/images/Image/Image"

import styles from "./styles.module.css"
import {Trans, useTranslation} from "react-i18next";

export const SupportSection: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.section}>
            <h2 className={styles.title}>
                {t("user:Home.SupportSection.title")}
            </h2>
            <div className={styles.content}>
                <div className={styles.image}>
                    <Image src={SupportSectionImage} alt="SupportSectionImage" skeletonHeight={574}/>
                </div>
                <p className={styles.description}>
                    <Trans i18nKey={"user:Home.SupportSection.description"} components={{
                        BreakRow: <br/>,
                        bold: <b/>,
                    }}/>
                </p>
            </div>
        </div>
    )
}
