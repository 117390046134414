export const capitalizeString = (source: string): string => (
    source.toLowerCase().replace( /\b./g, (char) => char.toUpperCase())
)

const getUserProfileColor = (pattern: string): string => {
    let hash = 0;
    for (let i = 0; i < pattern.length; i++) {
        hash = pattern.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).slice(-2);
    }
    return colour;
}
