import {FunctionComponent} from "react"

import {TopSection} from "@components/HomeSections/TopSection/TopSection";
import {TimingSection} from "@components/HomeSections/TimingSection/TimingSection";
import {EasyToUseSection} from "@components/HomeSections/EasyToUseSection/EasyToUseSection";
import {PricingSection} from "@components/HomeSections/PricingSection/PricingSection";

import styles from "./styles.module.css"
import {AboutUsSection} from "@components/HomeSections/AboutUsSection/AboutUsSection";
import {SupportedCompaniesSection} from "@components/HomeSections/SupportedCompaniesSection/SupportedCompaniesSection";
import {SupportSection} from "@components/HomeSections/SupportSection/SupportSection";
import {
    FrequentlyAskedQuestionsSection
} from "@components/HomeSections/FrequentlyAskedQuestionsSection/FrequentlyAskedQuestionsSection";

export const Home: FunctionComponent = () => {
    return (
        <div className={styles.page}>
            <TopSection/>
            <TimingSection/>
            <EasyToUseSection/>
            <PricingSection/>
            <AboutUsSection/>
            <SupportedCompaniesSection/>
            <SupportSection/>
            <FrequentlyAskedQuestionsSection/>
        </div>
    )
}