import {FunctionComponent, MouseEvent, useState} from "react"

import styles from "./styles.module.css"
import {useListProposalsQuery} from "@features/proposals/api";
import {useNavigate} from "react-router-dom";
import {pick} from "@helpers/object";
import {Inspection} from "@appTypes/inspection";
import {Proposal} from "@appTypes/proposal";
import {DataTable} from "@components/admin/DataTable/DataTable";
import {ListPagination} from "@components/common/paginators/ListPagination/ListPagination";
import {getEuropeFormattedDateTimeString} from "@helpers/date";

const displayingFields = ['id', 'name', 'email', 'date_created']

export const Proposals: FunctionComponent = () => {
    const navigate = useNavigate()

    const [currentPage, setPage] = useState<number>(0)
    const [rowsLimit, setRowsLimit] = useState<number>(10)

    const {data: proposalsList, isLoading} = useListProposalsQuery({offset: currentPage * rowsLimit, limit: rowsLimit}, {
        refetchOnMountOrArgChange: true,
    })

    if (isLoading) {
        return null
    }

    const proposals = proposalsList?.list?.map((proposal) => {
        const fields = pick(proposal, displayingFields) as Proposal
        return {
            id: fields.id,
            name: fields.name,
            email: fields.email,
            date_created: getEuropeFormattedDateTimeString(fields.date_created),
        }
    }) || []

    const count = proposalsList?.count ? proposalsList.count : proposals?.length

    const onRowClick = (event: MouseEvent<HTMLDivElement>, object: Inspection) => {
        event.persist()

        navigate(`/proposals/${object.id}`)
    }

    return (
        <div className={styles.page}>
            <DataTable
                headings={['ID', 'Name', 'E-mail', 'Date created']}
                data={proposals}
                onRowClick={onRowClick}
                classNames={{
                    tableClassName: styles.table,
                    tableHeaderClassName: styles.tableHeader,
                    rowClassName: styles.row,
                    cellClassName: styles.cell,
                    headingClassName: styles.heading,
                    bodyClassName: styles.body
                }}
            />
            <ListPagination count={count} limit={rowsLimit} setLimit={setRowsLimit} currentPage={currentPage}
                            setCurrentPage={setPage}/>
        </div>
    )
}