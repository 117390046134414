import {FunctionComponent, Suspense} from "react"

import styles from "./styles.module.css"
import SignInBackground from "@assets/media/backgrounds/SignInBackground.png";
import {ForgotPassword as Form} from "@components/user/forms/ForgotPassword/ForgotPassword"
import {useTranslation} from "react-i18next";

export const ForgotPassword: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.page}>
            <div className={styles.background}>
                <img src={SignInBackground} alt="SignInBackground"/>
            </div>
            <div className={styles.content}>
                <div>
                    <h1 className={styles.title}>
                        {t("user:ForgotPassword.title")}
                    </h1>
                    <p className={styles.description}>
                        {t("user:ForgotPassword.description")}
                    </p>
                    <Suspense fallback="Loading...">
                        <Form/>
                    </Suspense>
                </div>
            </div>
        </div>
    )
}