import {FunctionComponent, useEffect, useMemo, useState} from "react"

import styles from "./styles.module.css"
import {Company, Inspection} from "@appTypes/inspection";
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {Form, Formik, FormikErrors, FormikHelpers} from "formik";
import {Link} from "react-router-dom";
import {useAdminLoadInspectionCertificateMutation} from "@features/inspection/api";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";
import {GreenButton} from "@components/common/buttons/GreenButton/GreenButton";

import DownloadIcon from "@mui/icons-material/Download";
import {ImageLightbox} from "@helpers/components/ImageLightbox";

interface CertificateConstructorProps {
    inspection: Inspection,
    company: Company,
}

interface Values {
    [name: string]: string,
}

const CERTIFICATE_TEMPLATE_DATA_KEY = "inspection/certificates/drafts";

const getInspectionDraft = async (inspectionId: Inspection["id"], companyName: Company["name"]): Promise<Values> => {
    const cache = await caches.open(CERTIFICATE_TEMPLATE_DATA_KEY);

    const response = await cache.match(`${inspectionId}/${companyName}`);

    if (response) {
        return response.json();
    }

    return {};
}

export const CertificateConstructor: FunctionComponent<CertificateConstructorProps> = ({inspection, company}) => {
    const [inspectionDraft, setInspectionDraft] = useState<Values>({});

    const [isDraftSaving, setIsDraftSaving] = useState<boolean>(false);

    useEffect(() => {
        getInspectionDraft(inspection.id, company.name).then(result => {
            setInspectionDraft(result);
        });
    }, []);


    const initialValues: Values = Object.fromEntries(Object.entries((inspection.certificates
        .find((certificate) => certificate.company.id === company.id)?.fields_data || Object.fromEntries(inspection.required_fields[company.id].map((fieldName) => [fieldName, '']) || []))).map(([field, value]) => {

        if (field.toLowerCase().includes('has_renewed_battery')) {
            return ['invisible__' + field, +(inspection?.has_renewed_battery || value)]
        }
        if (field.toLowerCase().includes('is_battery_voltage_high_enough')) {
            return ['invisible__' + field, +(inspection?.is_battery_voltage_high_enough || value)]
        }

        if (value) {
            return [field, value]
        }

        if (field.toLowerCase().includes('driver') || field.toLowerCase().includes('host')) {
            if (field.toLowerCase().includes('name')) {
                return [field, `${inspection.user.first_name} ${inspection.user.last_name}`]
            }
            if (field.toLowerCase().includes('phone')) {
                return [field, inspection.user.phone_number]
            }
            if (field.toLowerCase().includes('email') || field.toLowerCase().includes('e-mail')) {
                return [field, inspection.user.email]
            }
        }
        if (field.toLowerCase().includes('license') && field.toLowerCase().includes('plate')) {
            return [field, inspection.license_plate_number]
        }

        if (field.toLowerCase().includes('inspection') && field.toLowerCase().includes('date')) {
            if (field.toLowerCase().includes('dd')) {
                return [field, (new Date()).getDate().toString().padStart(2, '0')]
            }
            if (field.toLowerCase().includes('mm')) {
                return [field, (new Date()).getMonth().toString().padStart(2, '0')]
            }
            if (field.toLowerCase().includes('yyyy')) {
                return [field, (new Date()).getFullYear().toString()]
            }
            return [field, (new Date()).toLocaleDateString('en-US')]
        }

        return [field, ""]
    }));

    const [loadCertificate, {isLoading}] = useAdminLoadInspectionCertificateMutation()

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        setSubmitting(true)

        const clearValues = Object.fromEntries(Object.entries(values).map(([key, value]) => [key.replace('invisible__', ''), value]))

        try {
            await loadCertificate({fields_data: {...clearValues}, company: company.id, inspection: inspection.id}).unwrap()
        } catch (e) {
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)
                setErrors(errors.errors as unknown as FormikErrors<Values>)
            }
        } finally {
            setSubmitting(false)
        }
    }

    const validate = (values: Values) => {
        const errors: Values = {}

        Object.keys(values).forEach((key) => {
            if (key.startsWith('invisible__')) {
                return
            }
            if (!values[key]) {
                errors[key] = 'required field'
            }
        })

        return errors
    }

    const certificateFile = inspection.certificates.find((certificate) => certificate.company.id === company.id)?.file || null;

    const updateLocalStorageCertificateData = async (values: Values) => {
        setIsDraftSaving(true);

        const cache = await caches.open(CERTIFICATE_TEMPLATE_DATA_KEY);

        const jsonValues = JSON.stringify(values);

        const jsonResponse = new Response(jsonValues, {
            headers: {
                'content-type': 'application/json'
            }
        });

        await cache.put(`${inspection.id}/${company.name}`, jsonResponse);

        setIsDraftSaving(false);
    }

    return (
        <div className={styles.contents}>
            <div className={styles.registrationCardPreview}>
                <div>
                    <ImageLightbox slides={[{ src: inspection.registration_card.file }]}>
                        <img src={inspection.registration_card.file} alt=""/>
                    </ImageLightbox>
                </div>
            </div>
            <Formik initialValues={{...initialValues, ...inspectionDraft}} enableReinitialize onSubmit={onSubmit} validate={validate}>
                {({isValid, isSubmitting, values}) => (
                    <Form className={styles.form}>
                        <div className={styles.fields}>
                            {inspection.required_fields[company.id].map((field, idx) => {
                                if (initialValues[`invisible__${field}`]) return;

                                const defaultValue = initialValues[field] ?? "";
                                const label = field.replaceAll("_", " ");

                                return (
                                    <WhiteInput label={label} name={field} key={idx} defaultValue={defaultValue}/>
                                );
                            })}
                        </div>
                        <div className={styles.submitButton}>
                            <GreenButton type="submit" disabled={!isValid || isLoading || isSubmitting}>
                                Load
                            </GreenButton>
                            <GreenButton onClick={() => updateLocalStorageCertificateData(values)} type="button" disabled={isLoading || isSubmitting || isDraftSaving}>
                                Save draft
                            </GreenButton>
                            {
                                certificateFile &&
                                <Link to={certificateFile} target="_blank" download className={styles.link}>
                                    {company.name} <DownloadIcon fontSize="inherit"/>
                                </Link>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
