import {api} from "@features/redux/api";
import {AuthToken, User} from "@appTypes/user";

type ConnectGoogleTwoFactorAuthQueryResult = {
    qr_code: string,
    recovery_key: string,
}
type ConnectGoogleTwoFactorAuthQueryArgs = void

type ConnectVerifyGoogleTwoFactorAuthQueryResult = {
    user: User,
    token: AuthToken,
}
type ConnectVerifyGoogleTwoFactorAuthQueryArgs = {
    code: string,
}

type VerifyGoogleTwoFactorAuthQueryResult = {
    user: User,
    token: AuthToken,
}
type VerifyGoogleTwoFactorAuthQueryArgs = {
    code: string,
}

type DisconnectGoogleTwoFactorAuthQueryResult = {
    user: User,
    token: AuthToken,
}
type DisconnectGoogleTwoFactorAuthQueryArgs = void

export const googleTwoFactorAuthApi = api.injectEndpoints({
    endpoints: builder => ({
        connectGoogleTwoFactorAuth: builder.query<ConnectGoogleTwoFactorAuthQueryResult, ConnectGoogleTwoFactorAuthQueryArgs>({
            query: () => ({
                url: "user/2fa/google/connect/",
                method: "GET",
            })
        }),
        connectVerifyGoogleTwoFactorAuth: builder.mutation<ConnectVerifyGoogleTwoFactorAuthQueryResult, ConnectVerifyGoogleTwoFactorAuthQueryArgs>({
            query: ({...args}) => ({
                url: "user/2fa/google/connect/verify/",
                method: "POST",
                body: args,
            })
        }),
        verifyGoogleTwoFactorAuth: builder.mutation<VerifyGoogleTwoFactorAuthQueryResult, VerifyGoogleTwoFactorAuthQueryArgs>({
            query: ({...args}) => ({
                url: "user/2fa/google/verify/",
                method: "POST",
                body: args,
            })
        }),
        disconnectGoogleTwoFactorAuth: builder.mutation<DisconnectGoogleTwoFactorAuthQueryResult, DisconnectGoogleTwoFactorAuthQueryArgs>({
            query: () => ({
                url: "user/2fa/google/disconnect/",
                method: "POST",
            })
        }),
    })
})

export const {
    useConnectGoogleTwoFactorAuthQuery,
    useConnectVerifyGoogleTwoFactorAuthMutation,
    useVerifyGoogleTwoFactorAuthMutation,
    useDisconnectGoogleTwoFactorAuthMutation,
} = googleTwoFactorAuthApi
