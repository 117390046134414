import {FunctionComponent, useState, MouseEvent} from "react"

import styles from "./styles.module.css"
import {CheckoutCompanies} from "@components/user/checkout/CheckoutCompanies/CheckoutCompanies";
import {clearFormData, getFormData} from "@helpers/multiStepForm";
import {NEW_INSPECTION_FORM_DATA_KEY} from "@helpers/constants/inspection";
import {Form, Formik, FormikErrors, FormikHelpers} from "formik";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {UserReInspectionQueryArgs, useUserReInspectionMutation} from "@features/inspection/api";
import {Link, useNavigate, useParams} from "react-router-dom";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";
import {CheckoutSummary} from "@components/user/checkout/CheckoutSummary/CheckoutSummary";
import {InspectionPhoto, InspectionVideo} from "@appTypes/files";
import {checkoutFormValidationSchema} from "@helpers/validationSchemas/new-inspection";
import {WhiteCheckbox} from "@components/common/checkboxes/WhiteCheckbox/WhiteCheckbox";
import {InspectionAgreementPopup} from "@components/user/popups/InspectionAgreementPopup/InspectionAgreementPopup";
import {TermsOfUsePopup} from "@components/user/popups/TermsOfUsePopup/TermsOfUsePopup";
import {useTranslation} from "react-i18next";

interface CheckoutProps {
    title?: string,
    description?: string,
}

interface Values {
    terms_of_use_agreement: boolean,
    inspection_agreement: boolean,
}

const initialValues: Values = {
    terms_of_use_agreement: false,
    inspection_agreement: false,
}

export const Checkout: FunctionComponent<CheckoutProps> = () => {
    const navigate = useNavigate()

    const {t} = useTranslation()

    const {id} = useParams()

    const [submitInspection, {isLoading}] = useUserReInspectionMutation()

    const [isTermsOfUseModalOpen, setTermsOfUseModalOpen] = useState<boolean>(false)
    const [isInspectionAgreementModalOpen, setInspectionAgreementModalOpen] = useState<boolean>(false)

    const inspectionData = getFormData(NEW_INSPECTION_FORM_DATA_KEY)

    const openTermsOfUseModal = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault()
        event.stopPropagation()

        setTermsOfUseModalOpen(true)
    }

    const openInspectionAgreementModal = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault()
        event.stopPropagation()

        setInspectionAgreementModalOpen(true)
    }

    const onSubmit = async (values: Values, {setErrors, setSubmitting}: FormikHelpers<Values>) => {
        try {
            const formData: { [key: string]: any } = {}

            for (const [key, value] of Object.entries(inspectionData)) {
                if (typeof value === 'object' && value?.hasOwnProperty('file')) {
                    formData[key] = (value as InspectionPhoto | InspectionVideo).id
                } else {
                    formData[key] = value
                }
            }

            await submitInspection({...formData, id: parseInt(id!)} as UserReInspectionQueryArgs).unwrap()

            clearFormData(NEW_INSPECTION_FORM_DATA_KEY)

            navigate("/dashboard", {replace: true, state: null})
        } catch (e) {
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)
                setErrors(errors.errors as FormikErrors<Values>)
            }
            setSubmitting(false)
        }
    }

    return (
        <div className={styles.step}>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={checkoutFormValidationSchema}>
                {({
                      isSubmitting,
                      errors,
                  }) => (
                    <Form className={styles.form}>
                        <CheckoutCompanies inspection_companies={inspectionData?.inspection_companies || []}/>
                        <CheckoutSummary inspection_companies={inspectionData?.inspection_companies || []}/>
                        <WhiteCheckbox label={<span>{t("user:NewInspection.Checkout.TermsOfUseCheckbox.label")} <Link to="#" className={styles.link}
                                                                                                                      onClick={openTermsOfUseModal}>{t("user:NewInspection.Checkout.TermsOfUseCheckbox.link")}</Link></span>}
                                       name="terms_of_use_agreement"/>
                        <WhiteCheckbox label={<span>{t("user:NewInspection.Checkout.InspectionAgreementCheckbox.label")} <Link to="#" className={styles.link}
                                                                                                                               onClick={openInspectionAgreementModal}>{t("user:NewInspection.Checkout.InspectionAgreementCheckbox.link")}</Link></span>}
                                       name="inspection_agreement"/>
                        <div className={styles.submitButton}>
                            <GreenRoundedButton type="submit" disabled={isSubmitting || !!Object.values(errors).length || isLoading}>
                                {t("common:Inspection.ReInspectButton")}
                            </GreenRoundedButton>
                        </div>
                    </Form>
                )}
            </Formik>
            <TermsOfUsePopup open={isTermsOfUseModalOpen} onClose={() => setTermsOfUseModalOpen(false)}/>
            <InspectionAgreementPopup open={isInspectionAgreementModalOpen}
                                      onClose={() => setInspectionAgreementModalOpen(false)}/>
        </div>
    )
}