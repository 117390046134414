import {useEffect, useRef} from "react";

export const useDebouncedFunction = (func: Function, delay: number, cleanUp: boolean = false) => {
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

    // Очистка таймера
    function clearTimer() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = undefined;
        }
    }

    // Очищаем таймер при анмаунте компонента, если cleanUp выставлен в true
    // и тем самым отменяем последний запланированный вызов
    useEffect(() => (cleanUp ? clearTimer : undefined), [cleanUp]);

    return (...args: any) => {
        clearTimer();
        timeoutRef.current = setTimeout(() => func(...args), delay);
    };
}