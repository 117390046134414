import {FunctionComponent} from "react"

import styles from "./styles.module.css"

interface InspectionCostCardProps {
    title: string,
    description: string,
    price?: number | string,
}

export const InspectionCostCard: FunctionComponent<InspectionCostCardProps> = ({title, description, price}) => {
    return (
        <div className={styles.card}>
            <div className={styles.labels}>
                <h3 className={styles.title}>
                    {title}
                </h3>
                <p className={styles.description}>
                    {description}
                </p>
            </div>
            <p className={styles.price}>
                {price}$
            </p>
        </div>
    )
}